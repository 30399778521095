var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toDo" },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "tip" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("Menu")))])
          ]),
          _vm._l(_vm.GetTraingAllPOPendingInfosData.Summarys, function(
            v,
            index
          ) {
            return _c(
              "div",
              { key: index, staticClass: "text-list" },
              [
                _c(
                  "el-row",
                  { staticStyle: { width: "700px" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 3, lg: 3, xl: 3 } },
                      [
                        _c("span", { staticClass: "lable" }, [
                          _vm._v(_vm._s(v.Title) + " ")
                        ])
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 3, lg: 3, xl: 3 } },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              " font-size: 14px; font-weight: 500; color: #454545; margin-right: 10px;"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                v.IsPO ? _vm.$t("BUYER") : _vm.$t("SUPPLIER")
                              ) + "："
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
                      [
                        _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(v.NameCompany))
                        ])
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "value-href",
                            on: {
                              click: function($event) {
                                return _vm.clickanchor(v.RelatedId)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("to do a total")) +
                                " : " +
                                _vm._s(v.Count ? v.Count : 0)
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._l(_vm.GetTraingAllPOPendingInfosData.Pendings, function(v, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("div", { staticClass: "tip" }, [
              _c("p", [_vm._v(_vm._s(v.Title))])
            ]),
            _vm._l(v.PendingInfos, function(v1, index1) {
              return _c(
                "div",
                { key: index1, staticClass: "text-list" },
                [
                  _c("div", { attrs: { id: v1.RelatedId } }),
                  _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "600",
                          color: "#454545"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            v1.IsPO ? _vm.$t("BUYER") : _vm.$t("SUPPLIER")
                          ) +
                            "：" +
                            _vm._s(v1.NameCompany)
                        )
                      ]
                    )
                  ]),
                  _vm._l(v1.PendingDetails, function(v2, index2) {
                    return _c(
                      "div",
                      {
                        key: index2,
                        staticClass: "text-list",
                        staticStyle: {
                          "margin-left": "0",
                          "padding-left": "0",
                          "font-weight": "400",
                          "margin-bottom": "17px"
                        }
                      },
                      [
                        _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "font-weight": "500",
                                color: "#454545"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(v2.IsPO ? _vm.$t("PO") : _vm.$t("SO")) +
                                  "：" +
                                  _vm._s(v2.Code)
                              )
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "font-weight": "500",
                                color: "#454545"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                                ) +
                                  "：" +
                                  _vm._s(v2.BusinessNoIn)
                              )
                            ]
                          )
                        ]),
                        _vm._l(v2.PendingDetails, function(v3, index3) {
                          return _c(
                            "div",
                            {
                              key: index3,
                              staticStyle: { "margin-bottom": "17px" }
                            },
                            [
                              v3.Type == "NoRelation"
                                ? _c("p", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "font-weight": "500",
                                          "margin-left": "20px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("NoRelation")))]
                                    )
                                  ])
                                : _vm._e(),
                              v3.Type == "NoWriteComponent"
                                ? _c("p", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "font-weight": "500",
                                          "margin-left": "20px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("NoWriteComponent"))
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              v3.Type == "NoUpinfoTop"
                                ? _c("p", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "font-weight": "500",
                                          "margin-left": "20px",
                                          color: "Highlight"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Unrecorded phase")) +
                                            "：" +
                                            _vm._s(v3.ProcessName)
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              v3.Type == "NoUpinfo"
                                ? _c("p", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "8px",
                                          "font-weight": "500",
                                          "margin-left": "40px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "No file is uploaded in phase"
                                            )
                                          ) + "："
                                        )
                                      ]
                                    ),
                                    _c("span", [_vm._v(_vm._s(v3.FileName))])
                                  ])
                                : _vm._e(),
                              v3.Type == "isFinish"
                                ? _c("p", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "font-weight": "500",
                                          "margin-left": "20px",
                                          color: "limegreen"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Completed")))]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  })
                ],
                2
              )
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }