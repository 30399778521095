var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "productTracing" }, [
    _c(
      "div",
      [
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }
                    },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: _vm.$t("queryParam"),
                          "prefix-icon": "el-icon-help",
                          size: "small",
                          "fetch-suggestions": _vm.querySearchCodeAsync
                        },
                        on: {
                          select: _vm.handleSelect,
                          change: _vm.handleChange
                        },
                        model: {
                          value: _vm.SeaCodeVal,
                          callback: function($$v) {
                            _vm.SeaCodeVal = $$v
                          },
                          expression: "SeaCodeVal"
                        }
                      }),
                      _c("el-button", {
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          icon: "iconfont icon-dingwei1",
                          type: "text",
                          size: "medium",
                          title: _vm.$t("Tracing")
                        },
                        on: {
                          click: function($event) {
                            return _vm.SelectData()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "text-align": "right"
                      },
                      attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }
                    },
                    [
                      _vm.isBrandBusiness_top &&
                      _vm.topInfo.TrackingApproveStatusVal == 660
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-check",
                                type: "success",
                                size: "medium",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.ApprovedsubmitForm(670)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("REVIEW")) +
                                  "\n                        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isBrandBusiness
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-check",
                                type: "success",
                                size: "medium",
                                round: "",
                                disabled: _vm.isCantApprove
                              },
                              on: {
                                click: function($event) {
                                  return _vm.ApprovedsubmitForm(660)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("APPROVED")) +
                                  "\n                        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isBrandBusiness
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-download",
                                type: "warning",
                                size: "medium",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.GetReview()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("DownloadPDF")) +
                                  "\n                        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "clearfix" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix-head",
                    staticStyle: {
                      padding: "0px 10px 0px 40px",
                      display: "flex"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          height: "100%",
                          width: "calc(100% - 120px)"
                        }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "colInfo",
                            staticStyle: { height: "100%" },
                            attrs: { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "10px" } },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      width: "80%",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap"
                                    }
                                  },
                                  [
                                    _vm.isBrandBusiness
                                      ? _c("span", {}, [
                                          _vm._v(_vm._s(_vm.$t("VENDOR")))
                                        ])
                                      : _c("span", {}, [
                                          _vm._v(_vm._s(_vm.$t("SUPPLIER")))
                                        ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "10px" }
                                      },
                                      [
                                        _vm._v(
                                          "：" + _vm._s(_vm.VendorCorporationId)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "colInfo",
                            staticStyle: { height: "100%" },
                            attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "10px" } },
                              [
                                _c("span", {}, [
                                  _vm._v(_vm._s(_vm.$t("STATE")) + ":")
                                ]),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "font-weight": "bold" },
                                    attrs: { type: "info", underline: false }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.GetStatusName(
                                            _vm.topInfo.TrackingApproveStatusVal
                                          )
                                        )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between"
                        }
                      },
                      [
                        _c("div", { staticClass: "d-f-c-c-t" }, [
                          _c("div", { staticClass: "icon" }, [
                            _vm.IsToLast
                              ? _c("img", {
                                  staticStyle: {
                                    width: "25px",
                                    height: "23px",
                                    "margin-right": "30px"
                                  },
                                  attrs: {
                                    src: "/img/speed/linkAll.png",
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  staticStyle: {
                                    width: "25px",
                                    height: "23px",
                                    "margin-right": "30px"
                                  },
                                  attrs: {
                                    src: "/img/speed/linkNo.png",
                                    alt: ""
                                  }
                                })
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c("div", { staticClass: "btn-b" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.Speed) +
                                  "\n                                "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", {}, [
                  _c("div", {
                    staticStyle: { height: "10px", background: "#f5faff" }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        background: "#F5FAFF",
                        "border-radius": "8px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                        "margin-bottom": "20px",
                        padding: "20px 40px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          staticStyle: {
                            width: "calc(100% - 120px)",
                            float: "left"
                          }
                        },
                        [
                          !_vm.IsTopCopy
                            ? _c(
                                "el-form",
                                { attrs: { model: _vm.topInfo } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.isBrandBusiness
                                                      ? _vm.$t(
                                                          "MASTER PO NUMBER"
                                                        )
                                                      : _vm.$t("PO")
                                                  ) +
                                                  "：\n                                            "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(_vm.topInfo.poVal))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("ORDER DATE")) +
                                                  ":"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(_vm.OrderDate))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Division")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.departVal)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Brand")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.brandVal)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _vm.isBrandBusiness
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MASTER STYLE NUMBER"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          !_vm.isBrandBusiness
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "FINISHED ITEM NUMBER"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.codeVal)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Product Name"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.productVal)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("COLOR")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.colorVal)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("ContractNo"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.topInfo.contractNoVal
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("QUANTITY")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.numberVal) +
                                                  "/" +
                                                  _vm._s(_vm.topInfo.unitVal)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      (_vm.topInfo.destinationCountryNameVal ==
                                      ""
                                      ? false
                                      : true)
                                        ? _c(
                                            "el-col",
                                            {
                                              staticClass: "colInfo",
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 8,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("MARKET"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.topInfo
                                                        .destinationCountryNameVal
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.topInfo.aCDateVal == ""
                                      ? false
                                      : true)
                                        ? _c(
                                            "el-col",
                                            {
                                              staticClass: "colInfo",
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 8,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("ACDate"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.topInfo.aCDateVal
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isBrandBusiness
                                        ? _c(
                                            "el-col",
                                            {
                                              staticClass: "colInfo",
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 8,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("SEASON"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.topInfo.SeasonVal
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isBrandBusiness
                                        ? _c(
                                            "el-col",
                                            {
                                              staticClass: "colInfo",
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 8,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("BATCH"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.topInfo.batchVal)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.IsTopCopy
                            ? _c(
                                "el-form",
                                { attrs: { model: _vm.topInfo } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.isBrandBusiness
                                                      ? _vm.$t(
                                                          "MASTER PO NUMBER"
                                                        )
                                                      : _vm.$t("PO")
                                                  ) +
                                                  "：\n                                            "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(_vm.topInfo.poVal))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("ORDER DATE")) +
                                                  ":"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(_vm.OrderDate))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Division")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.departVal)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Brand")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.brandVal)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("ContractNo"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.topInfo.contractNoVal
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      (_vm.topInfo.destinationCountryNameVal ==
                                      ""
                                      ? false
                                      : true)
                                        ? _c(
                                            "el-col",
                                            {
                                              staticClass: "colInfo",
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 8,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("MARKET"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.topInfo
                                                        .destinationCountryNameVal
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.topInfo.aCDateVal == ""
                                      ? false
                                      : true)
                                        ? _c(
                                            "el-col",
                                            {
                                              staticClass: "colInfo",
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 8,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("ACDate"))
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.topInfo.aCDateVal
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("SEASON")))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.topInfo.SeasonVal)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _vm.isBrandBusiness
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    "font-weight": "700",
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MASTER STYLE NUMBER"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Product Name"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("COLOR")))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "colInfo",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "font-weight": "700",
                                                "font-size": "14px"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("QUANTITY")))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.InfoTopCopy, function(
                                    copyitem,
                                    index
                                  ) {
                                    return _c(
                                      "el-row",
                                      { key: index },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 8,
                                              lg: 6,
                                              xl: 6
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [_vm._v(_vm._s(copyitem.Code))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 8,
                                              lg: 6,
                                              xl: 6
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [
                                                _vm._v(
                                                  _vm._s(copyitem.ProductName)
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 8,
                                              lg: 6,
                                              xl: 6
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [_vm._v(_vm._s(copyitem.Color))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 8,
                                              lg: 6,
                                              xl: 6
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [
                                                _vm._v(
                                                  _vm._s(copyitem.Number) +
                                                    "/" +
                                                    _vm._s(copyitem.UnitId)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              ref: "qrCodeUrl",
                              staticClass: "qrcode",
                              attrs: { id: "qrCodeUrl" },
                              on: { click: _vm.onPrint }
                            },
                            [
                              _c("vue-qr", {
                                attrs: {
                                  logoSrc: _vm.qrColorCode,
                                  logoScale: 20,
                                  margin: 2,
                                  colorDark: "#000",
                                  text: _vm.qrpocode,
                                  size: 120
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "8px",
                                "white-space": "nowrap"
                              }
                            },
                            [
                              _vm._v(
                                "GIGSC-" + _vm._s(_vm.topInfo.BusinessNoInVal)
                              )
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "info", round: "" },
                              on: { click: _vm.onPrint }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("PRINT QR-CODE")) +
                                  "\n                                "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "tracingList" },
                _vm._l(_vm.InfoData.activities, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "tli" },
                    [
                      _c("div", { staticClass: "topTitleCon" }, [
                        _c("i", {
                          staticClass: "iconfont icon-yuan",
                          staticStyle: { color: "#4D8AF0" }
                        }),
                        _vm._v(_vm._s(item.title))
                      ]),
                      _vm._l(item.children, function(time, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: {
                              "align-items": "flex-start",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "el-row",
                              {
                                staticStyle: {
                                  "background-color": "#dcdfe673",
                                  "padding-top": "5px"
                                }
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 12,
                                      xl: 12
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "topTitleCon2" }, [
                                      _c("i", {
                                        staticClass: "iconfont icon-yuan",
                                        staticStyle: { color: "#C2C7D2" }
                                      }),
                                      _vm._v(
                                        _vm._s(time.date) +
                                          "\n                                    "
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      "justify-content": "right",
                                      display: "flex"
                                    },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 12,
                                      xl: 12
                                    }
                                  },
                                  [
                                    time.IsHavePending && !time.isLastVar
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "justify-content": "center",
                                              display: "flex",
                                              width: "1px"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-badge",
                                              {
                                                staticClass: "item",
                                                attrs: { value: time.fileCount }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    height: "1.4rem",
                                                    width: "1.6rem"
                                                  },
                                                  attrs: {
                                                    title: _vm.$t("Jumptodo"),
                                                    src: "/img/speed/todo.png"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.lookInfo(
                                                        time.RelatedId
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    time.ApproveVar != "" && !time.isLastVar
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              "margin-left": "10px",
                                              width: "100px",
                                              "justify-content": "center",
                                              display: "flex"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-link",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold"
                                                },
                                                attrs: {
                                                  type: time.ApproveColorVar,
                                                  underline: false
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(time.ApproveVar)
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isBrandBusiness && !time.isLastVar
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "45px",
                                              "justify-content": "center",
                                              display: "flex"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-dropdown",
                                              { attrs: { trigger: "click" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-dropdown-link"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        height: "1.2rem",
                                                        width: "1.4rem",
                                                        "margin-top": "8px"
                                                      },
                                                      attrs: {
                                                        title: _vm.$t(
                                                          time.ApproveVar
                                                        ),
                                                        src: time.ApproveImgVar
                                                      }
                                                    }),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-arrow-down el-icon--right"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "el-dropdown-menu",
                                                  {
                                                    attrs: { slot: "dropdown" },
                                                    slot: "dropdown"
                                                  },
                                                  [
                                                    time.ApproveStatusVar > 320
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              icon:
                                                                "el-icon-s-check",
                                                              disabled:
                                                                time.isCantApproveVar
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.ApprovalHistory(
                                                                  time
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Approval History"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    time.LockedStatusVar != 605
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              icon:
                                                                "el-icon-close",
                                                              disabled:
                                                                time.isCantApproveVar
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.RejectForm(
                                                                  time
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Reject"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    time.ApproveStatusVar == 410
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              icon:
                                                                "el-icon-refresh-left",
                                                              disabled:
                                                                time.isCantApproveVar
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.recall(
                                                                  time
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "RECALL"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    time.LockedStatusVar !=
                                                      605 &&
                                                    time.ApproveStatusVar != 410
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              icon:
                                                                "el-icon-lock",
                                                              disabled:
                                                                time.isCantApproveVar
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.submitLock(
                                                                  time
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t("LOCK")
                                                                ) +
                                                                "\n                                                "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    time.LockedStatusVar ==
                                                      605 &&
                                                    time.ApproveStatusVar != 410
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              icon:
                                                                "el-icon-unlock",
                                                              disabled:
                                                                time.isCantApproveVar
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.submitUnlock(
                                                                  time
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Unlock"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    time.processList.length == 0
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "8px",
                                              "justify-content": "center",
                                              display: "flex"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "1.2rem",
                                                width: "1.4rem"
                                              },
                                              attrs: {
                                                title: _vm.$t(
                                                  "Dont need to declare"
                                                ),
                                                src:
                                                  "/img/speed/no_lookInfo.png"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    time.processList.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "8px",
                                              "justify-content": "center",
                                              display: "flex"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "1.2rem",
                                                width: "1.4rem"
                                              },
                                              attrs: {
                                                title: _vm.$t(
                                                  "DeclarationInformation"
                                                ),
                                                src: "/img/speed/lookInfo.png"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showField(
                                                    time.processList
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "padding-left": "35px" } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        time.VendorSectionSortVar < 2
                                          ? _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [_vm._v(_vm._s(_vm.$t("VENDOR")))]
                                            )
                                          : _vm._e(),
                                        time.VendorSectionSortVar > 1
                                          ? _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SUPPLIER"))
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [
                                            _vm._v(
                                              _vm._s(time.vendorVal) +
                                                _vm._s(time.ApproveVar)
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        time.VendorSectionSortVar < 2
                                          ? _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("MASTER PO NUMBER")
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        time.VendorSectionSortVar > 1
                                          ? _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [_vm._v(_vm._s(_vm.$t("PO")))]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [_vm._v(_vm._s(time.POVal))]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "fontTitle" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Division")) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [_vm._v(_vm._s(time.CdivisionVar))]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "fontTitle" },
                                          [_vm._v(_vm._s(_vm.$t("Brand")))]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [_vm._v(_vm._s(time.CbrandVar))]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        time.VendorSectionSortVar > 1
                                          ? _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "FINISHED ITEM NUMBER"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        time.VendorSectionSortVar < 2
                                          ? _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MASTER STYLE NUMBER"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [_vm._v(_vm._s(time.codeVal))]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "fontTitle" },
                                          [_vm._v(_vm._s(_vm.$t("COLOR")))]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [_vm._v(_vm._s(time.CcolorVar))]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "fontTitle" },
                                          [_vm._v(_vm._s(_vm.$t("QUANTITY")))]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [
                                            _vm._v(
                                              _vm._s(time.CnunberVar) +
                                                "/" +
                                                _vm._s(time.CunitVar)
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    time.VendorSectionSortVar > 1
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 8,
                                              lg: 6,
                                              xl: 6
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [_vm._v(_vm._s(_vm.$t("BATCH")))]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [_vm._v(_vm._s(time.title))]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    time.isRawFiberTypeId
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 6,
                                              lg: 6,
                                              xl: 6
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("FIBER TYPE"))
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [
                                                _vm._v(
                                                  _vm._s(time.RawFiberTypeIdVar)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    time.isVendorAddressId
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 6,
                                              lg: 6,
                                              xl: 6
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [_vm._v(_vm._s(_vm.$t("ORIGIN")))]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    time.VendorAddressIdVar
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "colInfo",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 12,
                                          xl: 12
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "fontTitle" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Product Name"))
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [_vm._v(_vm._s(time.CproductVar))]
                                        )
                                      ]
                                    ),
                                    (time.compositionVar != ""
                                    ? true
                                    : false)
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 12,
                                              lg: 12,
                                              xl: 12
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("COMPONENT"))
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [
                                                _vm._v(
                                                  _vm._s(time.compositionVar)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    time.isCollection &&
                                    time.CorporationId !=
                                      time.AffiliatedCorpCollectionId
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 16,
                                              lg: 12,
                                              xl: 12
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "CONTRACT SIGNING COMPANY"
                                                    )
                                                  ) + " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    time.AffiliatedCorpCollectionVar
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    time.isWorking &&
                                    time.CorporationId !=
                                      time.AffiliatedCorpWorkingId
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "colInfo",
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 16,
                                              lg: 12,
                                              xl: 12
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontTitle" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "ASSOCIATED PROCESSING PLANT"
                                                    )
                                                  ) + " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "fontCont" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    time.AffiliatedCorpWorkingVar
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._l(time.downlist, function(downs, index) {
                                  return time.downlist.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "downListCon"
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            {
                                              staticStyle: {
                                                "background-color":
                                                  "rgb(253 249 242)"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "700",
                                                        "font-size": "14px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("PO COMPANY")
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          downs.PoRowInfo
                                                            .VendorCorporationId
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 10,
                                                    xl: 10
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "700",
                                                        "font-size": "14px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("PO")) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          downs.PoRowInfo.Po
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 24,
                                                    md: 8,
                                                    lg: 8,
                                                    xl: 8
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "right"
                                                      }
                                                    },
                                                    [
                                                      downs.IsHavePending
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "5px",
                                                                "margin-left":
                                                                  "5px",
                                                                "justify-content":
                                                                  "center",
                                                                display: "flex",
                                                                width: "1px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-badge",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    value:
                                                                      downs.fileCount
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticStyle: {
                                                                      height:
                                                                        "1.2rem",
                                                                      width:
                                                                        "1.4rem",
                                                                      "margin-top":
                                                                        "5px"
                                                                    },
                                                                    attrs: {
                                                                      title: _vm.$t(
                                                                        "Jumptodo"
                                                                      ),
                                                                      src:
                                                                        "/img/speed/todo.png"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.lookInfo(
                                                                          downs.RelatedId
                                                                        )
                                                                      }
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      downs.ApproveInfo != ""
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "10px",
                                                                "margin-left":
                                                                  "10px",
                                                                width: "100px",
                                                                "justify-content":
                                                                  "center",
                                                                display: "flex"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-link",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold"
                                                                  },
                                                                  attrs: {
                                                                    type:
                                                                      downs.ApproveColor,
                                                                    underline: false
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        downs.ApproveInfo
                                                                      )
                                                                    ) +
                                                                      "\n                                                    "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.isBrandBusiness
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "45px",
                                                                "justify-content":
                                                                  "center",
                                                                display: "flex"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-dropdown",
                                                                {
                                                                  attrs: {
                                                                    trigger:
                                                                      "click"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "el-dropdown-link"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticStyle: {
                                                                            height:
                                                                              "1.2rem",
                                                                            width:
                                                                              "1.4rem",
                                                                            "margin-top":
                                                                              "8px"
                                                                          },
                                                                          attrs: {
                                                                            title: _vm.$t(
                                                                              downs.Approve
                                                                            ),
                                                                            src:
                                                                              downs.ApproveImg
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-arrow-down el-icon--right"
                                                                      })
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "el-dropdown-menu",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "dropdown"
                                                                      },
                                                                      slot:
                                                                        "dropdown"
                                                                    },
                                                                    [
                                                                      downs
                                                                        .PoRowDetailInfo
                                                                        .ApproveStatus >
                                                                      320
                                                                        ? _c(
                                                                            "el-dropdown-item",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "el-icon-s-check",
                                                                                disabled:
                                                                                  downs.IsCantApprove
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.ApprovalHistoryDown(
                                                                                    downs
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Approval History"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      downs
                                                                        .PoRowDetailInfo
                                                                        .LockedStatus !=
                                                                      605
                                                                        ? _c(
                                                                            "el-dropdown-item",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "el-icon-close",
                                                                                disabled:
                                                                                  downs.IsCantApprove
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.RejectDownForm(
                                                                                    downs
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Reject"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      downs
                                                                        .PoRowDetailInfo
                                                                        .ApproveStatus ==
                                                                      410
                                                                        ? _c(
                                                                            "el-dropdown-item",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "el-icon-refresh-left",
                                                                                disabled:
                                                                                  downs.IsCantApprove
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.recallDown(
                                                                                    downs
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "RECALL"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      downs
                                                                        .PoRowDetailInfo
                                                                        .LockedStatus !=
                                                                        605 &&
                                                                      downs
                                                                        .PoRowDetailInfo
                                                                        .ApproveStatus !=
                                                                        410
                                                                        ? _c(
                                                                            "el-dropdown-item",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "el-icon-lock",
                                                                                disabled:
                                                                                  downs.IsCantApprove
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.submitDownLock(
                                                                                    downs
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "LOCK"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      downs
                                                                        .PoRowDetailInfo
                                                                        .LockedStatus ==
                                                                        605 &&
                                                                      downs
                                                                        .PoRowDetailInfo
                                                                        .ApproveStatus !=
                                                                        410
                                                                        ? _c(
                                                                            "el-dropdown-item",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "el-icon-unlock",
                                                                                disabled:
                                                                                  downs.IsCantApprove
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.submitDownUnlock(
                                                                                    downs
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Unlock"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      downs.processList
                                                        .length == 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "padding-top":
                                                                  "8px",
                                                                "justify-content":
                                                                  "center",
                                                                display: "flex"
                                                              }
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  height:
                                                                    "1.2rem",
                                                                  width:
                                                                    "1.4rem"
                                                                },
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "Dont need to declare"
                                                                  ),
                                                                  src:
                                                                    "/img/speed/no_lookInfo.png"
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "padding-top":
                                                              "8px",
                                                            "justify-content":
                                                              "center",
                                                            display: "flex"
                                                          }
                                                        },
                                                        [
                                                          downs.processList
                                                            .length > 0
                                                            ? _c("img", {
                                                                staticStyle: {
                                                                  height:
                                                                    "1.2rem",
                                                                  width:
                                                                    "1.4rem"
                                                                },
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "DeclarationInformation"
                                                                  ),
                                                                  src:
                                                                    "/img/speed/lookInfo.png"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.showField(
                                                                      downs.processList
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              downs.PoRowInfo
                                                .AffiliatedCorpCollection !=
                                                null &&
                                              downs.PoRowInfo.CorporationId !=
                                                downs.PoRowInfo
                                                  .AffiliatedCorpCollection
                                                ? _c(
                                                    "el-col",
                                                    {
                                                      staticClass: "colInfo",
                                                      attrs: {
                                                        xs: 24,
                                                        sm: 12,
                                                        md: 16,
                                                        lg: 12,
                                                        xl: 12
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontTitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "CONTRACT SIGNING COMPANY"
                                                              )
                                                            ) + " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontCont"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              downs.PoRowInfo
                                                                .AffiliatedCorpCollectionName
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              downs.PoRowInfo
                                                .AffiliatedCorpWorking !=
                                                null &&
                                              downs.PoRowInfo.CorporationId !=
                                                downs.PoRowInfo
                                                  .AffiliatedCorpWorking
                                                ? _c(
                                                    "el-col",
                                                    {
                                                      staticClass: "colInfo",
                                                      attrs: {
                                                        xs: 24,
                                                        sm: 12,
                                                        md: 16,
                                                        lg: 12,
                                                        xl: 12
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontTitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "ASSOCIATED PROCESSING PLANT"
                                                              )
                                                            ) + " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                },
                                                [
                                                  downs.vendorSectionInfo.Sort >
                                                  1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontTitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "FINISHED ITEM NUMBER"
                                                              )
                                                            ) + " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  downs.vendorSectionInfo.Sort <
                                                  2
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontTitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "MASTER STYLE NUMBER"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          downs.PoRowDetailInfo
                                                            .Code
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fontTitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PO PRODUCT NAME"
                                                          )
                                                        ) + " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          downs.PoRowDetailInfo
                                                            .ProductName
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fontTitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("WASTAGE")
                                                        ) + " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(downs.LossRate) +
                                                          "%"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fontTitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "UseQuantityPO"
                                                          )
                                                        ) + " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          downs.UsedNumber
                                                        ) +
                                                          "/" +
                                                          _vm._s(
                                                            downs
                                                              .PoRowDetailInfo
                                                              .UnitId
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fontTitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("COLOR")
                                                        ) + " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          downs.PoRowDetailInfo
                                                            .Color
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "colInfo",
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fontTitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("BATCH")
                                                        ) + " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "fontCont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          downs.PoRowDetailInfo
                                                            .Batch
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              (downs.PoRowDetailInfo
                                                .RawFiberTypeId != ""
                                              ? true
                                              : false)
                                                ? _c(
                                                    "el-col",
                                                    {
                                                      staticClass: "colInfo",
                                                      attrs: {
                                                        xs: 24,
                                                        sm: 12,
                                                        md: 8,
                                                        lg: 6,
                                                        xl: 6
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontTitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "FIBER TYPE"
                                                              )
                                                            ) + " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontCont"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              downs
                                                                .PoRowDetailInfo
                                                                .RawFiberTypeName
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              (downs.PoRowDetailInfo
                                                .VendorAddressId != ""
                                              ? true
                                              : false)
                                                ? _c(
                                                    "el-col",
                                                    {
                                                      staticClass: "colInfo",
                                                      attrs: {
                                                        xs: 24,
                                                        sm: 12,
                                                        md: 8,
                                                        lg: 6,
                                                        xl: 6
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontTitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("ORIGIN")
                                                            ) + " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fontCont"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              downs
                                                                .PoRowDetailInfo
                                                                .VendorAddressId
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("RELATED PO"),
                    visible: _vm.dialogTableVisible,
                    "modal-append-to-body": false,
                    width: "90%",
                    top: "2vh"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogTableVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "avue-crud",
                    {
                      ref: "crud",
                      attrs: {
                        option: _vm.tableOpt,
                        "row-style": _vm.rowStyle,
                        "row-key": _vm.GetRowKeys,
                        data: _vm.potable,
                        page: _vm.page
                      },
                      on: {
                        "update:page": function($event) {
                          _vm.page = $event
                        },
                        "selection-change": _vm.selectionChange,
                        "current-change": _vm.currentChange
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "menu",
                          fn: function(ref) {
                            var row = ref.row
                            var index = ref.index
                            var size = ref.size
                            var type = ref.type
                            return [
                              _c("el-button", {
                                attrs: {
                                  icon: "icon-dingwei1",
                                  type: "text",
                                  size: "medium",
                                  title: _vm.$t("Look Over")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.submitSelectData(row)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        {
                          staticStyle: { width: "calc(100% - 80px)" },
                          slot: "menuLeft"
                        },
                        [
                          !_vm.isAdvancedSearch
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "align-self": "flex-end" },
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 24,
                                        xl: 24
                                      }
                                    },
                                    [
                                      _c("el-autocomplete", {
                                        staticStyle: { width: "550px" },
                                        attrs: {
                                          placeholder: _vm.$t("queryParam"),
                                          "prefix-icon": "el-icon-help",
                                          "fetch-suggestions":
                                            _vm.querySearchCodeAsync
                                        },
                                        on: {
                                          select: _vm.handleSelect,
                                          change: _vm.handleChange
                                        },
                                        model: {
                                          value: _vm.SeaCodeVal,
                                          callback: function($$v) {
                                            _vm.SeaCodeVal = $$v
                                          },
                                          expression: "SeaCodeVal"
                                        }
                                      }),
                                      _c(
                                        "el-badge",
                                        {
                                          staticStyle: {
                                            "margin-right": "18px"
                                          },
                                          attrs: { value: _vm.selectBadge }
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                plain: "",
                                                icon: "iconfont shaixuan2",
                                                size: "mini",
                                                title: _vm.$t("Display Filter")
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.isAdvancedSearch = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(_vm.$t("Filter"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            title: _vm.$t("Search"),
                                            icon: "iconfont sousuo",
                                            size: "mini",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.SelectData()
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(_vm.$t("Search")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdvancedSearch
                            ? _c(
                                "el-row",
                                {
                                  staticClass: "topFromSearch",
                                  attrs: { gutter: 20 }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { height: "auto" },
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 24,
                                        xl: 24
                                      }
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "formDiv",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 4,
                                            xl: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                _vm.SeaCodeVal == ""
                                                  ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                  : "top: -10px;left: 20px;transform: translate(0, 0);"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("KEYWORDS")) + ":"
                                              )
                                            ]
                                          ),
                                          _c("el-input", {
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.SeaCodeVal,
                                              callback: function($$v) {
                                                _vm.SeaCodeVal = $$v
                                              },
                                              expression: "SeaCodeVal"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "formDiv",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 4,
                                            xl: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                _vm.poVal == ""
                                                  ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                  : "top: -10px;left: 20px;transform: translate(0, 0);"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.isBrandBusiness
                                                    ? _vm.$t("MASTER PO NUMBER")
                                                    : _vm.$t("PO")
                                                ) + ":"
                                              )
                                            ]
                                          ),
                                          _c("el-input", {
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.poVal,
                                              callback: function($$v) {
                                                _vm.poVal = $$v
                                              },
                                              expression: "poVal"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "formDiv",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 4,
                                            xl: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                _vm.codeVal == ""
                                                  ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                  : "top: -10px;left: 20px;transform: translate(0, 0);"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.isBrandBusiness
                                                    ? _vm.$t(
                                                        "MASTER STYLE NUMBER"
                                                      )
                                                    : _vm.$t(
                                                        "FINISHED ITEM NUMBER"
                                                      )
                                                ) + ":"
                                              )
                                            ]
                                          ),
                                          _c("el-input", {
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.codeVal,
                                              callback: function($$v) {
                                                _vm.codeVal = $$v
                                              },
                                              expression: "codeVal"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "formDiv",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 4,
                                            xl: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                _vm.colorVal == ""
                                                  ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                  : "top: -10px;left: 20px;transform: translate(0, 0);"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("COLOR")) + ":"
                                              )
                                            ]
                                          ),
                                          _c("el-input", {
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.colorVal,
                                              callback: function($$v) {
                                                _vm.colorVal = $$v
                                              },
                                              expression: "colorVal"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "formDiv",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 4,
                                            xl: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                _vm.vendorVal.ids.length == 0
                                                  ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                  : "top: -10px;left: 20px;transform: translate(0, 0);",
                                              attrs: { id: "venSpan" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("VENDOR")) + ":"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                multiple: "",
                                                "collapse-tags": "",
                                                placeholder: "",
                                                clearable: ""
                                              },
                                              on: {
                                                focus: function($event) {
                                                  return _vm.focusDiv($event)
                                                },
                                                blur: function($event) {
                                                  return _vm.blurDiv($event)
                                                },
                                                change: _vm.venchang
                                              },
                                              model: {
                                                value: _vm.vendorVal.ids,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.vendorVal,
                                                    "ids",
                                                    $$v
                                                  )
                                                },
                                                expression: "vendorVal.ids"
                                              }
                                            },
                                            _vm._l(this.vendorData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "formDiv",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 4,
                                            xl: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                _vm.beginTimeVal == ""
                                                  ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                  : "top: -10px;left: 20px;transform: translate(0, 0);"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("ORDERBEINGTIME")
                                                ) + ":"
                                              )
                                            ]
                                          ),
                                          _c("el-date-picker", {
                                            staticClass: "dataicon",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              "prefix-icon": ""
                                            },
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.beginTimeVal,
                                              callback: function($$v) {
                                                _vm.beginTimeVal = $$v
                                              },
                                              expression: "beginTimeVal"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "formDiv",
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 4,
                                            xl: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                _vm.endTimeVal == ""
                                                  ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                  : "top: -10px;left: 20px;transform: translate(0, 0);"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("ORDERENDTIME")) +
                                                  ":"
                                              )
                                            ]
                                          ),
                                          _c("el-date-picker", {
                                            staticClass: "dataicon",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              "prefix-icon": ""
                                            },
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.endTimeVal,
                                              callback: function($$v) {
                                                _vm.endTimeVal = $$v
                                              },
                                              expression: "endTimeVal"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdvancedSearch
                            ? _c(
                                "el-row",
                                {
                                  staticClass: "topFromSearch",
                                  attrs: { gutter: 20 }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "align-self": "flex-end"
                                      },
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 24,
                                        xl: 24
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            plain: "",
                                            icon: "iconfont shaixuan1",
                                            size: "mini",
                                            title: _vm.$t("Hidden Filter")
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.isAdvancedSearch = false
                                              _vm.getSelectBadge()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("Hidden Filter")) +
                                              "\n                                    "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            title: _vm.$t("Search"),
                                            icon: "iconfont sousuo",
                                            size: "mini",
                                            type: "primary"
                                          },
                                          on: { click: _vm.searchFrom }
                                        },
                                        [_vm._v(" " + _vm._s(_vm.$t("Search")))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("template", { slot: "tip" })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("Attachment list"),
                    visible: _vm.fileDialogTableVisible,
                    "modal-append-to-body": false,
                    width: "90%",
                    top: "2vh"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.fileDialogTableVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "avue-crud",
                    {
                      ref: "fileCrud",
                      attrs: {
                        option: _vm.filetableOpt,
                        "row-style": _vm.rowStyle,
                        "row-key": _vm.GetRowKeys,
                        data: _vm.fileList,
                        page: _vm.page
                      },
                      on: {
                        "update:page": function($event) {
                          _vm.page = $event
                        },
                        "selection-change": _vm.selectionChange,
                        "current-change": _vm.currentChange
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "menu",
                          fn: function(ref) {
                            var row = ref.row
                            var index = ref.index
                            var size = ref.size
                            var type = ref.type
                            return [
                              _c("el-button", {
                                attrs: {
                                  icon: "el-icon-download",
                                  type: "text",
                                  size: "medium",
                                  title: _vm.$t("DOWNLOAD")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.downloadFile(row)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [_c("template", { slot: "tip" })],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("DeclarationInformation"),
                    visible: _vm.fieldDialogTableVisible,
                    "modal-append-to-body": false,
                    width: "90%",
                    top: "2vh"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.fieldDialogTableVisible = $event
                    },
                    close: function($event) {
                      _vm.fieldDialogTableVisible = false
                    }
                  }
                },
                _vm._l(_vm.fieldList, function(process, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { height: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "100%",
                            background: "#ebeef5",
                            "margin-bottom": "10px"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                height: "25px",
                                "font-weight": "bold",
                                "font-size": "14px",
                                color: "rgb(44, 141, 244)"
                              }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(process.title) +
                                  " :\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      process.subjectList.length == 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                height: "auto",
                                "border-bottom": "0.3rem solid #EBEEF5",
                                "margin-bottom": "10px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    height: "24px",
                                    "font-weight": "700",
                                    "font-size": "14px",
                                    color: "#ef5a5ac2"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "The declaration information is not uploaded"
                                      )
                                    ) + " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._l(process.subjectList, function(subject, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: {
                              width: "100%",
                              height: "auto",
                              "border-bottom": "0.3rem solid #EBEEF5",
                              "margin-bottom": "10px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap"
                                }
                              },
                              _vm._l(subject.fieldList, function(field, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      width: "25%",
                                      background: "#fff !important",
                                      display: "flex",
                                      "flex-direction": "column",
                                      height: "100%",
                                      "margin-bottom": "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fontTitle",
                                        staticStyle: { height: "24px" }
                                      },
                                      [_vm._v(_vm._s(field.name) + " ")]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: field.value,
                                          placement: "top-start"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontCont",
                                            staticStyle: {
                                              height: "24px",
                                              "text-overflow": "ellipsis",
                                              overflow: "hidden",
                                              "white-space": "nowrap",
                                              width: "250px"
                                            }
                                          },
                                          [_vm._v(_vm._s(field.value))]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "abbreviated-file",
                                  staticStyle: { display: "flex" }
                                },
                                _vm._l(subject.fileList, function(
                                  file,
                                  fileindex
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: fileindex,
                                      on: {
                                        click: function($event) {
                                          _vm.pdfYl(_vm.downloadFile(file.Id))
                                        }
                                      }
                                    },
                                    [
                                      _vm.iframePic(file.FileURL)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "file",
                                              staticStyle: {
                                                "margin-right": "10px"
                                              }
                                            },
                                            [
                                              _c("VuePdf", {
                                                attrs: {
                                                  src: _vm.downloadFile(file.Id)
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _c("img", {
                                              staticClass: "file",
                                              staticStyle: {
                                                "margin-right": "10px"
                                              },
                                              attrs: {
                                                src: _vm.downloadFile(file.Id),
                                                alt: ""
                                              }
                                            })
                                          ]),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            width: "100px",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [_vm._v(_vm._s(file.SupportFileName))]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("REJECT"),
                    visible: _vm.RejectdialogTableVisible,
                    "modal-append-to-body": false,
                    width: "70%",
                    top: "1vh"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.RejectdialogTableVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "rules",
                      attrs: {
                        model: _vm.RejectEdit,
                        rules: _vm.rules,
                        "label-position": "top",
                        "label-width": "150px"
                      }
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12, sm: 24, md: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("PO") } },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.RejectEdit.ApprovedObjectPO,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.RejectEdit,
                                          "ApprovedObjectPO",
                                          $$v
                                        )
                                      },
                                      expression: "RejectEdit.ApprovedObjectPO"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12, sm: 24, md: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("ORDERS COMPANY") } },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.RejectEdit.ApprovedObjectName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.RejectEdit,
                                          "ApprovedObjectName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "RejectEdit.ApprovedObjectName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24, sm: 24, md: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("REJECTION CATEGORY"),
                                    prop: "RejectCategory",
                                    rules: _vm.rules.RejectCategory
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        filterable: "",
                                        "allow-create": "",
                                        "default-first-option": "",
                                        placeholder: ""
                                      },
                                      model: {
                                        value: _vm.RejectEdit.RejectCategory,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "RejectCategory",
                                            $$v
                                          )
                                        },
                                        expression: "RejectEdit.RejectCategory"
                                      }
                                    },
                                    _vm._l(_vm.RejectTypeData, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: _vm.$t(item.value),
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24, sm: 24, md: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("REJECTION DESCRIPTION"),
                                    prop: "RejectRemark",
                                    rules: _vm.rules.RejectRemark
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      type: "textarea",
                                      maxlength: "300",
                                      "show-word-limit": "",
                                      autosize: { minRows: 4, maxRows: 6 }
                                    },
                                    model: {
                                      value: _vm.RejectEdit.RejectRemark,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.RejectEdit,
                                          "RejectRemark",
                                          $$v
                                        )
                                      },
                                      expression: "RejectEdit.RejectRemark"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            padding: "20px 0"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.isSaveDisable
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitReject("rules")
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Yes")))]
                          ),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.RejectdialogTableVisible = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("Approval History"),
                    visible: _vm.ApprovalHistorydialogTableVisible,
                    "modal-append-to-body": false,
                    width: "70%",
                    top: "1vh"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.ApprovalHistorydialogTableVisible = $event
                    }
                  }
                },
                [
                  _c("avue-crud", {
                    attrs: {
                      option: _vm.setData.tableOptRejectList,
                      data: _vm.tableDataRejectList,
                      "row-style": _vm.rowStyle,
                      page: _vm.pageRejectList
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.pageRejectList = $event
                      },
                      "current-change": _vm.currentRejectListChange
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { "background-color": "#ebeef5" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", { staticClass: "fontTitle" }, [
                            _vm._v(_vm._s(_vm.$t("Unapproved items")) + " :")
                          ]),
                          _c("span", { staticClass: "fontSumCont" }, [
                            _vm._v(_vm._s(_vm.bottomInfo.TotalNoApprove))
                          ])
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", { staticClass: "fontTitle" }, [
                            _vm._v(_vm._s(_vm.$t("Total suppliers")) + " :")
                          ]),
                          _c("span", { staticClass: "fontSumCont" }, [
                            _vm._v(" " + _vm._s(_vm.bottomInfo.TotalSuppliers))
                          ])
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", { staticClass: "fontTitle" }, [
                            _vm._v(_vm._s(_vm.$t("Total Po")) + " :")
                          ]),
                          _c("span", { staticClass: "fontSumCont" }, [
                            _vm._v(_vm._s(_vm.bottomInfo.TotalPo) + " ")
                          ])
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", { staticClass: "fontTitle" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Total submission times")) + " :"
                            )
                          ]),
                          _c("span", { staticClass: "fontSumCont" }, [
                            _vm._v(
                              _vm._s(_vm.bottomInfo.TotalSubmissionTimes) + " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                        [
                          _c("span", { staticClass: "fontTitle" }, [
                            _vm._v(_vm._s(_vm.$t("Total documents")) + " :")
                          ]),
                          _c("span", { staticClass: "fontSumCont" }, [
                            _vm._v(_vm._s(_vm.bottomInfo.TotalDocuments))
                          ])
                        ]
                      ),
                      _c("el-col", {
                        attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "upTit" }, [
                _vm._v(
                  _vm._s(_vm.$t("LoadingComplete")) +
                    _vm._s(_vm.$t("STIME")) +
                    ":" +
                    _vm._s(this.currentTime)
                )
              ])
            ],
            1
          )
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }