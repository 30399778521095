<template>
    <div class="productTracing">
        <div>
            <template>
                <div>
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;">
                            <el-autocomplete v-model="SeaCodeVal" :placeholder="$t('queryParam')"
                                prefix-icon="el-icon-help" style="width:300px;" size="small"
                                :fetch-suggestions="querySearchCodeAsync" @select="handleSelect" @change="handleChange">
                            </el-autocomplete>

                            <el-button icon="iconfont icon-dingwei1" type="text" size="medium" :title="$t('Tracing')"
                                style="margin-left:10px;" @click="SelectData()"></el-button>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
                            style="margin-bottom:20px;text-align: right;">
                            <el-button icon="el-icon-check" type="success" size="medium"
                                v-if="isBrandBusiness_top && topInfo.TrackingApproveStatusVal == 660" round
                                @click="ApprovedsubmitForm(670)">
                                {{ $t('REVIEW') }}
                            </el-button>
                            <el-button icon="el-icon-check" type="success" size="medium" v-if="isBrandBusiness" round
                                @click="ApprovedsubmitForm(660)" :disabled="isCantApprove">
                                {{ $t('APPROVED') }}
                            </el-button>
                            <el-button icon="el-icon-download" type="warning" size="medium" v-if="isBrandBusiness" round
                                @click="GetReview()">
                                {{ $t('DownloadPDF') }}
                            </el-button>
                        </el-col>
                    </el-row>
                    <div class="clearfix" style="">
                        <div class="clearfix-head" style="padding: 0px 10px 0px 40px; display: flex;">
                            <el-row style="height: 100%;width: calc(100% - 120px);">
                                <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18" class="colInfo"
                                    style="height: 100%;">
                                    <div style="padding-top:10px;">
                                        <p
                                            style="overflow:hidden;overflow:hidden;width: 80%;text-overflow:ellipsis;white-space:nowrap;">
                                            <span class="" v-if="isBrandBusiness">{{ $t('VENDOR') }}</span>
                                            <span class="" v-else>{{ $t('SUPPLIER') }}</span>
                                            <span style="margin-right: 10px;">：{{ VendorCorporationId }}</span>
                                        </p>
                                    </div>
                                </el-col>
                                <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo" style="height: 100%;">
                                    <div style="padding-top:10px;">
                                        <span class="">{{ $t('STATE') }}:</span>
                                        <el-link type="info" :underline="false" style="font-weight: bold;">
                                            {{ GetStatusName(topInfo.TrackingApproveStatusVal) }}</el-link>
                                    </div>
                                </el-col>
                            </el-row>
                            <div style="display:flex;justify-content: space-between;">
                                <div class="d-f-c-c-t" style="">
                                    <div class="icon">
                                        <img style="width: 25px;height: 23px;margin-right: 30px;" v-if="IsToLast"
                                            src="/img/speed/linkAll.png" alt="" />
                                        <img style="width: 25px;height: 23px;margin-right: 30px;" v-else
                                            src="/img/speed/linkNo.png" alt="" />
                                    </div>
                                </div>
                                <div style="display:flex;flex-direction: column;justify-content: center">
                                    <div class="btn-b">
                                        {{ Speed }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="">
                            <div style="height: 10px;background:#f5faff;"></div>
                            <div
                                style="background:#F5FAFF;border-radius:8px;display: flex;align-items: center;justify-content: space-between;margin-bottom:20px;padding:20px 40px;">
                                <div class="clearfix" style="width:calc(100% - 120px);float:left;">
                                    <el-form :model="topInfo" v-if="!IsTopCopy">
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">
                                                    {{
                                                            isBrandBusiness ? $t('MASTER PO NUMBER') : $t('PO')
                                                    }}：
                                                </span>
                                                <span class="fontCont">{{ topInfo.poVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('ORDER DATE')
                                                }}:</span>
                                                <span class="fontCont">{{ OrderDate }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('Division')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.departVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('Brand')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.brandVal }}</span>
                                            </el-col>

                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;"
                                                    v-if="isBrandBusiness">{{ $t('MASTER STYLE NUMBER') }}</span>
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;"
                                                    v-if="!isBrandBusiness">{{ $t('FINISHED ITEM NUMBER') }}</span>
                                                <span class="fontCont">{{ topInfo.codeVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('Product Name')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.productVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('COLOR')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.colorVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('ContractNo')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.contractNoVal }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('QUANTITY')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.numberVal }}/{{ topInfo.unitVal
                                                }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo"
                                                v-if="topInfo.destinationCountryNameVal == '' ? false : true">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('MARKET')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.destinationCountryNameVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo"
                                                v-if="topInfo.aCDateVal == '' ? false : true">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('ACDate')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.aCDateVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo"
                                                v-if="isBrandBusiness">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('SEASON')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.SeasonVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo"
                                                v-if="!isBrandBusiness">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('BATCH')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.batchVal }}</span>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                    <el-form :model="topInfo" v-if="IsTopCopy">
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">
                                                    {{
                                                            isBrandBusiness ? $t('MASTER PO NUMBER') : $t('PO')
                                                    }}：
                                                </span>
                                                <span class="fontCont">{{ topInfo.poVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('ORDER DATE')
                                                }}:</span>
                                                <span class="fontCont">{{ OrderDate }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('Division')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.departVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('Brand')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.brandVal }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('ContractNo')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.contractNoVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo"
                                                v-if="topInfo.destinationCountryNameVal == '' ? false : true">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('MARKET')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.destinationCountryNameVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo"
                                                v-if="topInfo.aCDateVal == '' ? false : true">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('ACDate')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.aCDateVal }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('SEASON')
                                                }}</span>
                                                <span class="fontCont">{{ topInfo.SeasonVal }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;"
                                                    v-if="isBrandBusiness">{{ $t('MASTER STYLE NUMBER') }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('Product Name')
                                                }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('COLOR')
                                                }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{
                                                        $t('QUANTITY')
                                                }}</span>
                                            </el-col>

                                        </el-row>
                                        <el-row v-for="(copyitem, index) in InfoTopCopy" v-bind:key="index">
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontCont">{{ copyitem.Code }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontCont">{{ copyitem.ProductName }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontCont">{{ copyitem.Color }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontCont">{{ copyitem.Number }}/{{ copyitem.UnitId
                                                }}</span>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                </div>
                                <div>
                                    <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl" @click="onPrint">
                                        <vue-qr :logoSrc="qrColorCode" :logoScale="20" :margin="2" colorDark='#000'
                                            :text="qrpocode" :size="120"></vue-qr>
                                    </div>
                                    <span style="font-size: 8px; white-space: nowrap;">GIGSC-{{ topInfo.BusinessNoInVal
                                    }}</span>
                                    <el-button size="mini" type="info" round @click="onPrint">{{ $t('PRINT QR-CODE') }}
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tracingList">
                        <div class="tli" v-for="(item, index) in InfoData.activities" v-bind:key="index">
                            <div class="topTitleCon"><i style="color:#4D8AF0" class="iconfont icon-yuan"></i>{{
                                    item.title
                            }}</div>
                            <div v-for="(time, index) in item.children" :key="index"
                                style="align-items: flex-start; justify-content: space-between;">
                                <el-row style="background-color: #dcdfe673;padding-top:5px;">
                                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;">
                                        <div class="topTitleCon2">
                                            <i style="color:#C2C7D2" class="iconfont icon-yuan"></i>{{ time.date }}
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
                                        style="justify-content: right; display: flex;">
                                        <div style="justify-content: center; display: flex;width: 1px; "
                                            v-if="time.IsHavePending && !time.isLastVar">
                                            <el-badge :value="time.fileCount" class="item">
                                                <img style="height: 1.4rem; width: 1.6rem;"
                                                    @click="lookInfo(time.RelatedId)" :title="$t('Jumptodo')"
                                                    src="/img/speed/todo.png" />
                                            </el-badge>
                                        </div>
                                        <div style="margin-right: 10px; margin-left: 10px; width: 100px; justify-content: center; display: flex;"
                                            v-if="time.ApproveVar != '' && !time.isLastVar">
                                            <el-link :type="time.ApproveColorVar" :underline="false"
                                                style="font-weight: bold;">{{ $t(time.ApproveVar) }}</el-link>
                                        </div>
                                        <div style="width:45px;justify-content: center;display: flex;"
                                            v-if="isBrandBusiness && !time.isLastVar">
                                            <!--<el-tooltip class="item" effect="light" placement="top-start">
            <div slot="content" v-for="(item,index) in time.Approvelist" v-bind:key="index">
                {{ formatterApprove(item) }}
            </div>
        </el-tooltip>-->
                                            <el-dropdown trigger="click">
                                                <span class="el-dropdown-link">
                                                    <img style="height: 1.2rem; width: 1.4rem; margin-top: 8px; "
                                                        :title="$t(time.ApproveVar)" :src="time.ApproveImgVar" /><i
                                                        class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item icon="el-icon-s-check"
                                                        @click.native="ApprovalHistory(time)"
                                                        v-if="time.ApproveStatusVar > 320"
                                                        :disabled="time.isCantApproveVar">
                                                        {{ $t('Approval History') }}
                                                    </el-dropdown-item>
                                                    <el-dropdown-item icon="el-icon-close"
                                                        @click.native="RejectForm(time)"
                                                        v-if="time.LockedStatusVar != 605"
                                                        :disabled="time.isCantApproveVar">
                                                        {{ $t('Reject') }}
                                                    </el-dropdown-item>
                                                    <el-dropdown-item icon="el-icon-refresh-left"
                                                        @click.native="recall(time)" v-if="time.ApproveStatusVar == 410"
                                                        :disabled="time.isCantApproveVar">
                                                        {{ $t('RECALL') }}
                                                    </el-dropdown-item>
                                                    <el-dropdown-item icon="el-icon-lock"
                                                        @click.native="submitLock(time)"
                                                        v-if="time.LockedStatusVar != 605 && time.ApproveStatusVar != 410"
                                                        :disabled="time.isCantApproveVar">
                                                        {{ $t('LOCK') }}
                                                    </el-dropdown-item>
                                                    <el-dropdown-item icon="el-icon-unlock"
                                                        @click.native="submitUnlock(time)"
                                                        v-if="time.LockedStatusVar == 605 && time.ApproveStatusVar != 410"
                                                        :disabled="time.isCantApproveVar">
                                                        {{ $t('Unlock') }}
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>

                                        </div>
                                        <div style="padding-top: 8px;justify-content: center; display: flex;"
                                            v-if="time.processList.length == 0">
                                            <img style="height: 1.2rem; width: 1.4rem; "
                                                :title="$t('Dont need to declare')" src="/img/speed/no_lookInfo.png" />
                                        </div>
                                        <div style="padding-top: 8px;  justify-content: center; display: flex;"
                                            v-if="time.processList.length > 0">
                                            <img style="height: 1.2rem; width: 1.4rem;"
                                                @click="showField(time.processList)"
                                                :title="$t('DeclarationInformation')" src="/img/speed/lookInfo.png" />
                                        </div>
                                    </el-col>
                                </el-row>
                                <div style="padding-left: 35px;">
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar < 2">{{ $t('VENDOR')
                                            }}</span>
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar > 1">{{
                                                    $t('SUPPLIER')
                                            }}</span>
                                            <span class="fontCont">{{ time.vendorVal }}{{ time.ApproveVar }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar < 2">{{ $t('MASTER PO NUMBER') }}</span>
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar > 1">{{ $t('PO')}}</span>
                                            <span class="fontCont">{{ time.POVal }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                            <span class="fontTitle">{{ $t('Division') }} </span>
                                            <span class="fontCont">{{ time.CdivisionVar }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                            <span class="fontTitle">{{ $t('Brand') }}</span>
                                            <span class="fontCont">{{ time.CbrandVar }}</span>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar > 1">{{ $t('FINISHED ITEM NUMBER') }}</span>
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar < 2">{{ $t('MASTER STYLE NUMBER') }}</span>
                                            <span class="fontCont">{{ time.codeVal }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                            <span class="fontTitle">{{ $t('COLOR') }}</span>
                                            <span class="fontCont">{{ time.CcolorVar }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                            <span class="fontTitle">{{ $t('QUANTITY') }}</span>
                                            <span class="fontCont">{{ time.CnunberVar }}/{{ time.CunitVar }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo"
                                            v-if="time.VendorSectionSortVar > 1">
                                            <span class="fontTitle">{{ $t('BATCH') }}</span>
                                            <span class="fontCont">{{ time.title }}</span>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-if="time.isRawFiberTypeId"
                                            class="colInfo">
                                            <span class="fontTitle">{{ $t('FIBER TYPE') }}</span>
                                            <span class="fontCont">{{ time.RawFiberTypeIdVar }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-if="time.isVendorAddressId"
                                            class="colInfo">
                                            <span class="fontTitle">{{ $t('ORIGIN') }}</span>
                                            <span class="fontCont">{{ time.VendorAddressIdVar }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="colInfo">
                                            <span class="fontTitle">{{ $t('Product Name') }}</span>
                                            <span class="fontCont">{{ time.CproductVar }}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"
                                            v-if="time.compositionVar != '' ? true : false" class="colInfo">
                                            <span class="fontTitle">{{ $t('COMPONENT') }}</span>
                                            <span class="fontCont">{{ time.compositionVar }}</span>
                                        </el-col>

                                    </el-row>
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="16" :lg="12" :xl="12"
                                            v-if="time.isCollection && time.CorporationId != time.AffiliatedCorpCollectionId"
                                            class="colInfo">
                                            <span class="fontTitle">{{ $t('CONTRACT SIGNING COMPANY') }} </span>
                                            <span class="fontCont">{{ time.AffiliatedCorpCollectionVar }}</span>
                                        </el-col>

                                        <el-col :xs="24" :sm="12" :md="16" :lg="12" :xl="12"
                                            v-if="time.isWorking && time.CorporationId != time.AffiliatedCorpWorkingId"
                                            class="colInfo">
                                            <span class="fontTitle">{{ $t('ASSOCIATED PROCESSING PLANT') }} </span>
                                            <span class="fontCont">{{ time.AffiliatedCorpWorkingVar }}</span>
                                        </el-col>
                                    </el-row>

                                    <div v-for="(downs, index) in time.downlist" v-bind:key="index"
                                        v-if="time.downlist.length > 0" class="downListCon">
                                        <el-row style="background-color: rgb(253 249 242)">
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">

                                                <span style=" font-weight: 700; font-size: 14px;">{{ $t('PO COMPANY')
                                                }}</span>
                                                <span class="fontCont">{{ downs.PoRowInfo.VendorCorporationId }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="10" :xl="10" class="colInfo">
                                                <span style=" font-weight: 700; font-size: 14px;">{{ $t('PO') }} </span>
                                                <span class="fontCont">{{ downs.PoRowInfo.Po }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                                <div style="display: flex;justify-content: right;">
                                                    <div style="margin-right: 5px; margin-left: 5px; justify-content: center; display: flex; width: 1px;"
                                                        v-if="downs.IsHavePending">
                                                        <el-badge :value="downs.fileCount" class="item">
                                                            <img style="height: 1.2rem; width: 1.4rem;margin-top: 5px;"
                                                                @click="lookInfo(downs.RelatedId)"
                                                                :title="$t('Jumptodo')" src="/img/speed/todo.png" />
                                                        </el-badge>
                                                    </div>
                                                    <div style="margin-right: 10px; margin-left: 10px; width: 100px; justify-content: center; display: flex; "
                                                        v-if="downs.ApproveInfo != ''">
                                                        <el-link :type="downs.ApproveColor" :underline="false"
                                                            style="font-weight: bold;">{{ $t(downs.ApproveInfo) }}
                                                        </el-link>
                                                    </div>
                                                    <div style="width: 45px; justify-content: center; display: flex;"
                                                        v-if="isBrandBusiness">
                                                        <!--<el-tooltip class="item" effect="light" placement="top-start">
                                            <div slot="content" v-for="(item,index) in downs.ApproveList" v-bind:key="index">
                                                {{ formatterApprove(item) }}
                                            </div>
                                        </el-tooltip>-->
                                                        <el-dropdown trigger="click">
                                                            <span class="el-dropdown-link">
                                                                <img style="height: 1.2rem; width: 1.4rem;margin-top:8px;"
                                                                    :title="$t(downs.Approve)"
                                                                    :src="downs.ApproveImg" /><i
                                                                    class="el-icon-arrow-down el-icon--right"></i>
                                                            </span>
                                                            <el-dropdown-menu slot="dropdown">
                                                                <el-dropdown-item icon="el-icon-s-check"
                                                                    @click.native="ApprovalHistoryDown(downs)"
                                                                    v-if="downs.PoRowDetailInfo.ApproveStatus > 320"
                                                                    :disabled="downs.IsCantApprove">
                                                                    {{ $t('Approval History') }}
                                                                </el-dropdown-item>
                                                                <el-dropdown-item icon="el-icon-close"
                                                                    @click.native="RejectDownForm(downs)"
                                                                    v-if="downs.PoRowDetailInfo.LockedStatus != 605"
                                                                    :disabled="downs.IsCantApprove">
                                                                    {{ $t('Reject') }}
                                                                </el-dropdown-item>
                                                                <el-dropdown-item icon="el-icon-refresh-left"
                                                                    @click.native="recallDown(downs)"
                                                                    v-if="downs.PoRowDetailInfo.ApproveStatus == 410"
                                                                    :disabled="downs.IsCantApprove">
                                                                    {{ $t('RECALL') }}
                                                                </el-dropdown-item>
                                                                <el-dropdown-item icon="el-icon-lock"
                                                                    @click.native="submitDownLock(downs)"
                                                                    v-if="downs.PoRowDetailInfo.LockedStatus != 605 && downs.PoRowDetailInfo.ApproveStatus != 410"
                                                                    :disabled="downs.IsCantApprove">
                                                                    {{ $t('LOCK') }}
                                                                </el-dropdown-item>
                                                                <el-dropdown-item icon="el-icon-unlock"
                                                                    @click.native="submitDownUnlock(downs)"
                                                                    v-if="downs.PoRowDetailInfo.LockedStatus == 605 && downs.PoRowDetailInfo.ApproveStatus != 410"
                                                                    :disabled="downs.IsCantApprove">
                                                                    {{ $t('Unlock') }}
                                                                </el-dropdown-item>
                                                            </el-dropdown-menu>
                                                        </el-dropdown>

                                                    </div>
                                                    <div style="padding-top:8px; justify-content: center; display: flex;"
                                                        v-if="downs.processList.length == 0">
                                                        <img style="height: 1.2rem; width: 1.4rem; "
                                                            :title="$t('Dont need to declare')"
                                                            src="/img/speed/no_lookInfo.png" />
                                                    </div>
                                                    <div
                                                        style="padding-top:8px;  justify-content: center; display: flex;">
                                                        <img style="height: 1.2rem; width: 1.4rem; "
                                                            @click="showField(downs.processList)"
                                                            v-if="downs.processList.length > 0"
                                                            :title="$t('DeclarationInformation')"
                                                            src="/img/speed/lookInfo.png" />
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="16" :lg="12" :xl="12"
                                                v-if="downs.PoRowInfo.AffiliatedCorpCollection != null && downs.PoRowInfo.CorporationId != downs.PoRowInfo.AffiliatedCorpCollection"
                                                class="colInfo">
                                                <span class="fontTitle">{{ $t('CONTRACT SIGNING COMPANY') }} </span>
                                                <span class="fontCont">{{ downs.PoRowInfo.AffiliatedCorpCollectionName
                                                }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="16" :lg="12" :xl="12"
                                                v-if="downs.PoRowInfo.AffiliatedCorpWorking != null && downs.PoRowInfo.CorporationId != downs.PoRowInfo.AffiliatedCorpWorking"
                                                class="colInfo">
                                                <span class="fontTitle">{{ $t('ASSOCIATED PROCESSING PLANT') }} </span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontTitle" v-if="downs.vendorSectionInfo.Sort > 1">{{
                                                        $t('FINISHED ITEM NUMBER')
                                                }} </span>
                                                <span class="fontTitle" v-if="downs.vendorSectionInfo.Sort < 2">{{
                                                        $t('MASTER STYLE NUMBER')
                                                }}</span>
                                                <span class="fontCont">{{ downs.PoRowDetailInfo.Code }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontTitle">{{ $t('PO PRODUCT NAME') }} </span>
                                                <span class="fontCont">{{ downs.PoRowDetailInfo.ProductName }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontTitle">{{ $t('WASTAGE') }} </span>
                                                <span class="fontCont">{{ downs.LossRate }}%</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontTitle">{{ $t('UseQuantityPO') }} </span>
                                                <span class="fontCont">{{ downs.UsedNumber }}/{{
                                                        downs.PoRowDetailInfo.UnitId
                                                }}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontTitle">{{ $t('COLOR') }} </span>
                                                <span class="fontCont">{{ downs.PoRowDetailInfo.Color }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                                <span class="fontTitle">{{ $t('BATCH') }} </span>
                                                <span class="fontCont">{{ downs.PoRowDetailInfo.Batch }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
                                                v-if="downs.PoRowDetailInfo.RawFiberTypeId != '' ? true : false"
                                                class="colInfo">
                                                <span class="fontTitle">{{ $t('FIBER TYPE') }} </span>
                                                <span class="fontCont">{{ downs.PoRowDetailInfo.RawFiberTypeName
                                                }}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
                                                v-if="downs.PoRowDetailInfo.VendorAddressId != '' ? true : false"
                                                class="colInfo">
                                                <span class="fontTitle">{{ $t('ORIGIN') }} </span>
                                                <span class="fontCont">{{ downs.PoRowDetailInfo.VendorAddressId
                                                }}</span>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <el-dialog :title="$t('RELATED PO')" :visible.sync="dialogTableVisible"
                        :modal-append-to-body="false" width="90%" top="2vh">
                        <avue-crud ref="crud" :option="tableOpt" :row-style="rowStyle" :row-key="GetRowKeys"
                            :data="potable" :page.sync="page" @selection-change="selectionChange"
                            @current-change="currentChange">
                            <template slot="menuLeft" style="width: calc(100% - 80px);">
                                <el-row v-if="!isAdvancedSearch">
                                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="align-self: flex-end; ">
                                        <el-autocomplete v-model="SeaCodeVal" :placeholder="$t('queryParam')"
                                            prefix-icon="el-icon-help" style="width:550px;"
                                            :fetch-suggestions="querySearchCodeAsync" @select="handleSelect"
                                            @change="handleChange">
                                        </el-autocomplete>
                                        <el-badge :value="selectBadge" style="margin-right: 18px;">
                                            <el-button plain icon="iconfont shaixuan2" size="mini"
                                                :title="$t('Display Filter')" @click="isAdvancedSearch = true;">
                                                {{ $t('Filter') }}</el-button>
                                        </el-badge>
                                        <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini"
                                            type="primary" @click="SelectData()"> {{ $t('Search') }}</el-button>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
                                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                            <span
                                                :style="SeaCodeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                                        $t('KEYWORDS')
                                                }}:</span>
                                            <el-input @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                v-model="SeaCodeVal"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                            <span
                                                :style="poVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                                        isBrandBusiness ?
                                                            $t('MASTER PO NUMBER') : $t('PO')
                                                }}:</span>
                                            <el-input v-model="poVal" @focus="focusDiv($event)" @blur="blurDiv($event)">
                                            </el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                            <span
                                                :style="codeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                                        isBrandBusiness ?
                                                            $t('MASTER STYLE NUMBER') : $t('FINISHED ITEM NUMBER')
                                                }}:</span>
                                            <el-input v-model="codeVal" @focus="focusDiv($event)"
                                                @blur="blurDiv($event)"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                            <span
                                                :style="colorVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                                        $t('COLOR')
                                                }}:</span>
                                            <el-input v-model="colorVal" @focus="focusDiv($event)"
                                                @blur="blurDiv($event)"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                            <span id="venSpan"
                                                :style="vendorVal.ids.length == 0 ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                                        $t('VENDOR')
                                                }}:</span>
                                            <el-select v-model="vendorVal.ids" @focus="focusDiv($event)"
                                                @blur="blurDiv($event)" multiple collapse-tags placeholder=""
                                                style="width:100%;" @change="venchang" clearable>
                                                <el-option v-for="item in this.vendorData" :key="item.value"
                                                    :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                            <span
                                                :style="beginTimeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                                        $t('ORDERBEINGTIME')
                                                }}:</span>
                                            <el-date-picker class="dataicon" v-model="beginTimeVal" type="date"
                                                @focus="focusDiv($event)" @blur="blurDiv($event)" format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                            </el-date-picker>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                            <span
                                                :style="endTimeVal == '' ? 'top: 50%;left: 50%;transform: translate(-50%, -50%);' : 'top: -10px;left: 20px;transform: translate(0, 0);'">{{
                                                        $t('ORDERENDTIME')
                                                }}:</span>
                                            <el-date-picker class="dataicon" v-model="endTimeVal" type="date"
                                                @focus="focusDiv($event)" @blur="blurDiv($event)" format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                            </el-date-picker>
                                        </el-col>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
                                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                                        style=" text-align: right; align-self: flex-end;">
                                        <el-button plain icon="iconfont shaixuan1" size="mini"
                                            :title="$t('Hidden Filter')"
                                            @click="isAdvancedSearch = false; getSelectBadge()"> {{ $t('Hidden Filter')
                                            }}
                                        </el-button>
                                        <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini"
                                            type="primary" @click="searchFrom"> {{ $t('Search') }}</el-button>
                                    </el-col>
                                </el-row>
                            </template>
                            <template slot-scope="{row,index,size,type}" slot="menu">
                                <el-button icon="icon-dingwei1" type="text" size="medium" :title="$t('Look Over')"
                                    @click="submitSelectData(row)"></el-button>
                            </template>
                            <!--<template slot="header">
            <el-button type="primary" @click="submitSelectData()">{{$t('Yes')}}</el-button>
            </template>-->
                            <template slot="tip">
                            </template>
                        </avue-crud>
                    </el-dialog>
                    <el-dialog :title="$t('Attachment list')" :visible.sync="fileDialogTableVisible"
                        :modal-append-to-body="false" width="90%" top="2vh">
                        <avue-crud ref="fileCrud" :option="filetableOpt" :row-style="rowStyle" :row-key="GetRowKeys"
                            :data="fileList" :page.sync="page" @selection-change="selectionChange"
                            @current-change="currentChange">
                            <template slot-scope="{row,index,size,type}" slot="menu">
                                <el-button icon="el-icon-download" type="text" size="medium" :title="$t('DOWNLOAD')"
                                    @click="downloadFile(row)"></el-button>
                            </template>
                            <template slot="tip">
                            </template>
                        </avue-crud>
                    </el-dialog>
                    <el-dialog :title="$t('DeclarationInformation')" :visible.sync="fieldDialogTableVisible"
                        :modal-append-to-body="false" width="90%" top="2vh" @close="fieldDialogTableVisible = false">
                        <div style="height: 100%;" v-for="(process, index) in fieldList " v-bind:key="index">
                            <div style="height: 100%; background: #ebeef5; margin-bottom: 10px;">
                                <span
                                    style="height: 25px; font-weight: bold; font-size: 14px; color: rgb(44, 141, 244);">
                                    {{
                                            process.title
                                    }} :
                                </span>
                            </div>
                            <div v-if="process.subjectList.length == 0"
                                style="width: 100%; height: auto; border-bottom: 0.3rem solid #EBEEF5; margin-bottom: 10px; ">
                                <span style="height: 24px; font-weight: 700; font-size: 14px; color: #ef5a5ac2; ">{{
                                        $t('The declaration information is not uploaded')
                                }} </span>
                            </div>
                            <div v-for="(subject, index) in         process.subjectList "
                                style="width: 100%; height: auto; border-bottom: 0.3rem solid #EBEEF5; margin-bottom: 10px; "
                                v-bind:key="index">
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div style=" width: 25%; background: #fff !important; display: flex; flex-direction: column; height: 100%; margin-bottom: 10px;"
                                        v-for="(field, index) in subject.fieldList" v-bind:key="index">
                                        <span style="height: 24px;" class="fontTitle">{{ field.name }} </span>
                                        <el-tooltip effect="dark" :content="field.value" placement="top-start">
                                            <span
                                                style="height: 24px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 250px;"
                                                class="fontCont">{{ field.value }}</span>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div class="abbreviated-file" style="display:flex;">
                                        <div v-for="(file, fileindex) in subject.fileList"
                                            @click="pdfYl(downloadFile(file.Id))" :key="fileindex">
                                            <div class="file" style="margin-right: 10px;"
                                                v-if="iframePic(file.FileURL)">
                                                <!--                        <iframe :src="downloadFile(file.Id) + '&t=' + new Date().getTime()"
                                                  scrolling="none"
                                                  style="overflow: hidden;"></iframe>-->
                                                <VuePdf :src="downloadFile(file.Id)" />
                                            </div>
                                            <div v-else>
                                                <img :src="downloadFile(file.Id)" alt="" class="file"
                                                    style="margin-right: 10px">
                                                <!--                        <el-image
                                                    style="width: 100px; height: 100px;margin-right: 10px"
                                                    :src="downloadFile(file.Id)"
                                                    :preview-src-list="[downloadFile(file.Id)]">
                                          </el-image>-->
                                            </div>
                                            <p style="overflow:hidden;width: 100px;white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;">{{ file.SupportFileName }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-dialog>
                    <el-dialog :title="$t('REJECT')" :visible.sync="RejectdialogTableVisible"
                        :modal-append-to-body="false" width="70%" top="1vh">
                        <el-form :model="RejectEdit" :rules="rules" ref="rules" label-position="top"
                            label-width="150px">
                            <el-row>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('PO')">
                                        <el-input v-model="RejectEdit.ApprovedObjectPO"
                                            style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('ORDERS COMPANY')">
                                        <el-input v-model="RejectEdit.ApprovedObjectName"
                                            style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :sm="24" :md="24">
                                    <el-form-item :label="$t('REJECTION CATEGORY')" prop="RejectCategory"
                                        :rules="rules.RejectCategory">
                                        <el-select v-model="RejectEdit.RejectCategory" filterable allow-create
                                            default-first-option style="width: calc(100% - 115px);" placeholder="">
                                            <el-option v-for="item in RejectTypeData" :key="item.value"
                                                :label="$t(item.value)" :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <!--<el-autocomplete 
                                                   v-model="RejectEdit.RejectCategory"
                                                   :fetch-suggestions="RejectCategorySearch"
                                                   :popper-append-to-body="false"
                                                   clearable></el-autocomplete>-->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :sm="24" :md="24">
                                    <el-form-item :label="$t('REJECTION DESCRIPTION')" prop="RejectRemark"
                                        :rules="rules.RejectRemark">
                                        <el-input v-model="RejectEdit.RejectRemark" style="width: calc(100% - 115px);"
                                            type="textarea" maxlength="300" show-word-limit
                                            :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div style="text-align:center;padding:20px 0;">
                                <el-button type="primary" :disabled="isSaveDisable" @click="submitReject('rules');">{{
                                        $t('Yes')
                                }}</el-button>
                                <el-button @click="RejectdialogTableVisible = false">{{ $t('Cancel') }}</el-button>
                            </div>
                        </el-form>
                    </el-dialog>
                    <el-dialog :title="$t('Approval History')" :visible.sync="ApprovalHistorydialogTableVisible"
                        :modal-append-to-body="false" width="70%" top="1vh">
                        <avue-crud :option="setData.tableOptRejectList" :data="tableDataRejectList"
                            :row-style="rowStyle" :page.sync="pageRejectList" @current-change="currentRejectListChange">
                        </avue-crud>
                    </el-dialog>


                    <div style="text-align:center">
                        <el-row style="background-color:#ebeef5;">
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{ $t('Unapproved items') }} :</span>
                                <span class="fontSumCont">{{ bottomInfo.TotalNoApprove }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{ $t('Total suppliers') }} :</span>
                                <span class="fontSumCont"> {{ bottomInfo.TotalSuppliers }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{ $t('Total Po') }} :</span>
                                <span class="fontSumCont">{{ bottomInfo.TotalPo }} </span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{ $t('Total submission times') }} :</span>
                                <span class="fontSumCont">{{ bottomInfo.TotalSubmissionTimes }} </span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{ $t('Total documents') }} :</span>
                                <span class="fontSumCont">{{ bottomInfo.TotalDocuments }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <!--<span class="fontCont">{{bottomInfo.CproductVar}}</span>-->
                            </el-col>
                        </el-row>
                    </div>

                    <div class="upTit">{{ $t('LoadingComplete') }}{{ $t('STIME') }}:{{ this.currentTime }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped>
.item {
    margin-top: 10px;
    margin-right: 40px;
}

.new-btn-box {
    justify-content: center;
    padding: 0 10px 0 10px;
    height: 35px;
    background: #5D9FF5;
    box-shadow: 0px 2px 4px 0px rgba(7, 62, 134, 0.43);
    border-radius: 4px;
}

.el-dialog__wrapper {
    transition-duration: 0.3s;
}

.tracingList .el-timeline-item {
    padding-left: 110px;
    position: relative;
}

.tracingList .el-timeline-item__timestamp {
    position: absolute;
    left: -110px;
    top: 0;
    margin-top: 2px;
}

.tracingList .el-timeline-item__tail {
    position: absolute;
    left: 114px !important;
    top: 2px !important;
}

.tracingList .el-timeline-item__node {
    position: absolute;
    left: 109px !important;
    top: 0 !important;
}

.topTitleCon {
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    font-weight: 700;
}

.topTitleCon i {
    vertical-align: 1px;
    font-size: 13px !important;
    margin-right: 15px;
    -webkit-font-smoothing: antialiased;
}

.topTitleCon2 {
    font-size: 12px;
    color: #333;
    padding-left: 3px;
    box-sizing: border-box;
    font-weight: 500;
}

.topTitleCon2 i {
    vertical-align: 1px;
    font-size: 13px !important;
    margin-right: 15px;
    -webkit-font-smoothing: antialiased;
}

.downIcon i {
    margin: 0 !important;
    margin-top: -2px !important;
}

.downIcon {
    color: #409EFF;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    border-color: #409EFF;
    padding: 0 !important;
}

.tli {
    border: 1px dashed #979797;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}

.tli .el-collapse-item__header {
    height: 35px;
    background-color: #ebeef5;
}

.tli .el-timeline-item {
    padding-bottom: 1px;
}

.upTit {
    font-size: 8px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid #f5f7fa;
    font-weight: 700;
    color: #ccc;
}

.colInfo {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.downListCon {
    border-bottom: 2px solid #EBEEF5;
    /*  border: .0425rem dashed #979797;
background-color: rgb(253 249 242);*/
}

.fontTitle {
    font-weight: 700;
    font-size: 14px;
    background: #ecf5ff7d;
}

.fontCont {
    font-size: 0.65rem;
    color: #303133;
}

.fontSumCont {
    font-size: 1rem;
    FONT-WEIGHT: 800;
    color: #333;
}

.abbreviated-file {
    padding-bottom: 20px;
}

.abbreviated-file .file {
    width: 100px;
    height: 160px;
    border: 1px dashed #eee;
}

.abbreviated-file .file iframe {
    width: 100%;
    border: none;
    height: 100%;
    overflow: hidden;
}

.clearfix-head {
    height: 40px;
    background: #E3EFFF;
    border-radius: 4px 4px 0 0;
}

.btn-b {
    width: 55px;
    height: 31px;
    background: #5D9FF5;
    box-shadow: 0px 2px 4px 0px rgba(7, 62, 134, 0.43);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.d-flex {
    display: flex;
}

.d-f-c-c-t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.d-f-c-c-t img {
    cursor: pointer;
}
</style>
<script>
import { AllPOList, getDate, getDateYYYMMDD, PoAndDetailList, DecCompleted } from "@/api/purchaseOrd";
import { TracingUnifiedIsBool, TracingDropDownList, TracingStringList, TracingUnifiedHelpReturn } from "@/api/unified";
import { add, GetOrderPackage } from "@/api/orderPackage";
import VuePdf from "vue-pdf";
import { ListApproves, addApprovesSo, addUnlockSo, addLockSo, addApprovesPo, addUnlockPo, addLockPo, addRecallPo, addRecallSo } from "@/api/orderApprove";
import { pvhApiBase } from '@/config/env'
import VueQr from 'vue-qr'

export default {
    props: {
        FlookInfo: Function
    },
    data() {
        return {
            SeaCodeVal: '',
            selectBadge: null,
            isAdvancedSearch: false,
            isAllSubAppved: true,
            isSaveDisable: false,
            IsToLast: false,
            Speed: 0,
            VendorCorporationId: '',
            OrderDate: '',
            activeName: 'first',
            isBrandBusiness: false,
            isBrandBusiness_top: false,
            codeVal: "",
            poVal: "",
            colorVal: "",
            beginTimeVal: "",
            endTimeVal: "",
            vendorVal: { ids: [], },
            vendorData: [],
            businessNoInVal: '',
            businessNoOutVal: '',
            loadId: '',
            qrpocode: '',
            qrColorCode: '',
            currentTime: "",
            Coderestaurants: [],
            Codestate: '',
            Codetimeout: null,
            potable: [],
            codeslist: [],
            selectedData: [],//采购商
            bottomInfo: {
                TotalNoApprove: 0, TotalSuppliers: 0, TotalPo: 0, TotalSubmissionTimes: 0, TotalDocuments: 0,
            },
            IsTopCopy: false,
            InfoTopCopy: [],
            isCantApprove: true,
            isCantReview: true,
            topInfo: {
                brandVal: '',
                departVal: '',
                productVal: '',
                supplierVal: '',
                codeVal: '',
                poVal: '',
                batchVal: '',
                contractNoVal: '',
                IngredientVal: '',
                productNameVal: '',
                colorVal: '',
                numberVal: '',
                unitVal: '',
                destinationCountryNameVal: '',
                TrackingApproveStatusVal: 0,
                aCDateVal: '',
                SeasonVal: '',
                BusinessNoInVal: '',
            },
            InfoData: {
                activities: [{
                    title: '',
                    children: [{
                        date: '',
                        vendorVal: '',
                        codeVal: '',
                        POVal: '',
                        processList: [],
                        processCount: '',
                        fileCount: '',
                        fileList: [{ name: '', fileURL: '' }],
                        downlist: [],
                        title: '',
                        CbrandVar: '',
                        CdivisionVar: '',
                        CsupplierVar: '',
                        CproductVar: '',
                        CproductCodeVar: '',
                        CbatchVar: '',
                        CcolorVar: '',
                        CnunberVar: '',
                        CunitVar: ''
                        ,
                        compositionVar: '',
                        isCollection: false,
                        isWorking: false,
                        AffiliatedCorpCollectionVar: "",
                        AffiliatedCorpWorkingVar: "",
                        CorporationId: "",
                        AffiliatedCorpCollectionId: "",
                        AffiliatedCorpWorkingId: "",
                        BusinessNoInVar: "",
                        BusinessNoOutVar: "",
                        LossRateVar: 0,
                        isRawFiberTypeId: false,
                        RawFiberTypeIdVar: "",
                        isVendorAddressId: false,
                        VendorAddressIdVar: "",
                        LockedStatusVar: 0,
                        ApproveStatusVar: 0,
                        isCantApproveVar: true,
                        isLastVar: false,
                        VendorSectionSortVar: 0,
                        poDetailVar: '',
                        statusColorVar: 'info',
                        statusVar: '',
                        statuslist: [],
                        collapseName: [],
                        ApproveImgVar: '',
                        ApproveColorVar: 'info',
                        ApproveVar: '',
                        Approvelist: [],
                        IsHavePending: 0,
                        RelatedId: ''
                    }]
                }]
            },
            no_SeaCodeVal: 'no',
            selectId: '',
            SeaCodeVal: '',
            dialogTableVisible: false,
            fileDialogTableVisible: false,
            fileList: [], //当前查询值
            fieldDialogTableVisible: false,
            fieldList: [],
            RejectdialogTableVisible: false,
            ApprovalHistorydialogTableVisible: false,
            tableDataRejectList: [],
            RejectTypeData: [
                {
                    label: this.$t('Missing Attachments'),
                    value: "Missing Attachments"
                },
                {
                    label: this.$t('Low Resolution In Attachments'),
                    value: "Low Resolution In Attachments"
                },
                {
                    label: this.$t('Non-Requested Files Are Uploaded'),
                    value: "Non-Requested Files Are Uploaded"
                },
                {
                    label: this.$t('Filled In The Wrong Content'),
                    value: "Filled In The Wrong Content"
                },

                {
                    label: this.$t('Incomplete Content'),
                    value: "Incomplete Content"
                },
                {
                    label: this.$t('Other'),
                    value: "Other"
                }],
            RejectCategoryData: [],
            RejectEdit: {
                DetailOrderId: '',
                ApprovedObject: '',
                ApprovedObjectName: '',
                ApprovedObjectPO: '',
                RejectCategory: '',
                RejectRemark: '',
                isPo: true,
            },
            rules: {
                RejectCategory: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                RejectRemark: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
            },
            page: {
                currentPage: 1,
                total: 0,
                layout: "total,pager,prev, next",
                background: true,
                pageSize: 10
            },
            pageRejectList: {
                currentPage: 1,
                total: 0,
                layout: "total,pager,prev, next",
                background: true,
                pageSize: 10
            },
            tableOpt: {
                page: true,
                refreshBtn: false,
                columnBtn: false,
                simplePage: true,
                addBtn: false,
                addRowBtn: false,
                cellBtn: false,
                index: true,
                indexLabel: ' ',
                border: true,
                align: "center",
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                menuWidth: 200,
                editBtn: false,
                delBtn: false,
                //selection: true,
                //reserveSelection: true,
                tip: false,
                column: [
                    {
                        label: this.$t('PO'),
                        prop: 'po',
                    },
                    {
                        label: this.$t('VENDOR'),
                        prop: 'vendorCorporationId',
                    },
                    {
                        label: this.$t('DATE'),
                        prop: 'orderDate',
                        type: "date",
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd',
                    },
                    {
                        label: this.$t('ProductCode'),
                        prop: 'code',
                    },
                    {
                        label: this.$t('Product Name'),
                        prop: 'productName',
                    },
                    {
                        label: this.$t('BATCH'),
                        prop: 'batch',
                    },
                    {
                        label: this.$t('COLOR'),
                        prop: 'color',
                    },
                    {
                        label: this.$t('UNIT'),
                        prop: 'unitId',
                    },
                    {
                        label: this.$t('OrderNumber'),
                        prop: 'number',
                    }
                ]
            },
            filetableOpt: {
                page: false,
                refreshBtn: false,
                columnBtn: false,
                simplePage: true,
                addBtn: false,
                addRowBtn: false,
                editBtn: false,
                delBtn: false,
                cellBtn: false,
                index: true,
                indexLabel: ' ',
                border: true,
                align: "center",
                menuTitle: this.$t('OPERATION'),
                emptyText: this.$t('No Data'),
                tip: false,
                column: [
                    {
                        label: this.$t('PROCESS NAME'),
                        prop: 'ProcessName',
                    },
                    {
                        label: this.$t('FILE TYPE'),
                        prop: 'SupportFileName',
                    },
                    {
                        label: this.$t('FILE NAME'),
                        prop: 'Name',
                    },
                    {
                        label: this.$t('Index'),
                        prop: 'FileURL',
                        hide: true,
                    },
                ]
            },
            RejectStatusData: [
                {
                    label: this.$t('Rejected'),
                    value: 0
                },
                {
                    label: this.$t('Rejected'),
                    value: 410
                },
                {
                    label: this.$t('RECALL'),
                    value: 420
                },
                {
                    label: this.$t('Resubmitted'),
                    value: 550
                },
                {
                    label: this.$t('Locked'),
                    value: 605
                },
                {
                    label: this.$t('Approved'),
                    value: 660
                },
                {
                    label: this.$t('Reviewed'),
                    value: 670
                }],
        }
    },
    computed: {
        setData() {
            return {
                tableOptRejectList: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    editBtn: false,
                    delBtn: false,
                    cellBtn: false,
                    index: true,
                    menu: false,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    tip: false,
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'CreateTime',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('STATE'),
                            prop: 'Status',
                            placeholder: ' ',
                            type: "select",
                            dicData: this.RejectStatusData,
                        },
                        {
                            label: this.$t('ORDERS COMPANY'),
                            prop: 'ApprovedObject',
                        },
                        {
                            label: this.$t('REJECTION CATEGORY'),
                            prop: 'RejectCategory',
                            placeholder: ' ',
                            type: "select",
                            dicData: this.RejectTypeData,

                        },
                        {
                            label: this.$t('REJECTION DESCRIPTION'),
                            prop: 'RejectRemark',
                        },
                        {
                            label: this.$t('RESUBMISSION INSTRUCTIONS'),
                            prop: 'Remarks',
                        },
                    ]
                }
            }
        }
    },
    mounted() {
        this.init();// 在加载的时候执行了这个方法，可以调用这个根据ID来插叙的方法
        this.Coderestaurants = this.getCodeList();//数据源
    },
    components: {
        VueQr,
        VuePdf
    },
    watch: {
        $route() {
            this.init()
        }
    },
    methods: {
        getSelectBadge() {
            this.selectBadge = 0;
            if (this.codeVal != "") {
                ++this.selectBadge;
            };
            if (this.poVal != "") {
                ++this.selectBadge;
            };
            if (this.colorVal != "") {
                ++this.selectBadge;
            };
            if (this.beginTimeVal != "") {
                ++this.selectBadge;
            };
            if (this.endTimeVal != "") {
                ++this.selectBadge;
            };
            if (this.vendorVal.ids.length != 0) {
                ++this.selectBadge;
            };
            if (this.selectBadge == 0) {
                this.selectBadge = null;
            }
        },
        lookInfo(RelatedId) {
            this.FlookInfo('ToDo', RelatedId)
        },
        //设置表头行的样式
        tableHeaderColor() {
            return 'background-color:#838bb3!important;padding: 0!important;'

        },//表格行央视
        rowStyle({ rowIndex }) {
            if (rowIndex % 2 === 0) {
                return {
                    backgroundColor: '#F8F8F8'
                }
            }
        },
        moreFrom() {
            let fromHei = document.querySelector(".fromOutDiv").clientHeight;
            if (fromHei > 60) {
                document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
            } else {
                document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
            }
        },  //点击更多按钮
        focusDiv(obj) {//搜索框获取焦点
            if (obj.currentTarget === undefined) {
                obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            } else {
                if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                    obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }

            }
        },//搜索框失去焦点
        blurDiv(obj) {
            if (obj.currentTarget === undefined) {
                if (obj.value === "") {
                    obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            } else if (obj.currentTarget === null) {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            } else {
                if (obj.target.value == "") {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                }
            }
        },
        venchang() {
            if (this.vendorVal.ids.length === 0) {
                document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
            } else {
                document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
            }
        },//点击更多按钮
        searchFrom() {
            this.no_SeaCodeVal = "yes";
            this.getPurOrdList();
        },//查询
        getCodeList() {
            this.Codeslist = [];
            TracingStringList("ALLPurchaseOrderInfo").then(res => {
                for (var i = 0; i < res.data.length; i++) {
                    this.Codeslist.push({
                        value: res.data[i]
                    })
                }
            })
            return this.Codeslist;
        },
        init() {
            this.loadId = this.$route.query.ID;
            TracingDropDownList('VendorList').then(res => {
                this.vendorData = res.data;
            });
            TracingUnifiedIsBool("OrderIsBrandBusiness", this.loadId).then(res => {
                this.isBrandBusiness = res.data;
            });
            TracingUnifiedIsBool("IsBrandBusiness").then(res => {
                this.isBrandBusiness_top = res.data;
            });
            AllPOList(this.loadId).then(res => {
                //底部
                this.bottomInfo.TotalOrderProgress = res.data.TotalOrderProgress;
                this.bottomInfo.TotalNoApprove = res.data.TotalNoApprove;
                this.bottomInfo.TotalSuppliers = res.data.TotalSuppliers;
                this.bottomInfo.TotalPo = res.data.TotalPo;
                this.bottomInfo.TotalSubmissionTimes = res.data.TotalSubmissionTimes;
                this.bottomInfo.TotalDocuments = res.data.TotalDocuments;
                this.IsTopCopy = res.data.IsTopCopy;
                console.log('IsTopCopy', this.IsTopCopy);
                this.InfoTopCopy = res.data.ListTopPoDetailInfo;
                //头部
                this.topInfo.supplierVal = res.data.TopPoInfo.VendorCorporationId;
                this.topInfo.codeVal = res.data.TopPoDetailInfo.Code;
                this.topInfo.brandVal = res.data.TopPoInfo.Brand;
                this.topInfo.departVal = res.data.TopPoInfo.DepartmentId;
                this.topInfo.productVal = res.data.TopPoDetailInfo.ProductName;
                this.topInfo.poVal = res.data.TopPoInfo.Po;
                this.topInfo.batchVal = res.data.TopPoDetailInfo.Batch;
                this.topInfo.contractNoVal = res.data.TopPoInfo.ContractId;
                this.topInfo.IngredientVal = res.data.TopPoDetailInfo.Ingredient;
                this.topInfo.productNameVal = res.data.TopPoDetailInfo.ProductName;
                this.topInfo.colorVal = res.data.TopPoDetailInfo.Color;
                this.topInfo.numberVal = res.data.TopPoDetailInfo.Number;
                this.topInfo.destinationCountryNameVal = res.data.TopPoDetailInfo.DestinationCountryName;
                this.topInfo.unitVal = res.data.TopPoDetailInfo.UnitId;
                this.topInfo.aCDateVal = res.data.TopPoInfo.ACDate == null ? "" : getDateYYYMMDD(res.data.TopPoInfo.ACDate);
                this.topInfo.SeasonVal = res.data.TopPoInfo.Season + " " + res.data.TopPoInfo.SeasonYear;
                this.topInfo.BusinessNoInVal = res.data.TopPoDetailInfo.BusinessNoIn;
                this.topInfo.TrackingApproveStatusVal = res.data.TopPoDetailInfo.TrackingApproveStatus;
                this.IsToLast = res.data.TopPoDetailInfo.IsToLast;
                this.OrderDate = getDateYYYMMDD(res.data.TopPoInfo.OrderDate);
                this.VendorCorporationId = res.data.TopPoInfo.VendorCorporationId;
                this.Speed = res.data.TopPoDetailInfo.Speed;
                this.isCantApprove = (this.Speed == "100%" || this.Speed == "100.0%") && this.topInfo.TrackingApproveStatusVal < 660 && this.IsToLast ? false : true;
                this.isCantReview = this.topInfo.TrackingApproveStatusVal == 660 && this.IsToLast ? false : true;
                var eachspo = [];
                var forchildrn = [];
                var eachspolist = [];
                this.InfoData.activities = [];
                eachspo = res.data.eachStagePOList;
                for (var i = 0; i < eachspo.length; i++) {
                    forchildrn = eachspo[i].children;
                    var childrenlistVar = [];
                    for (var j = 0; j < forchildrn.length; j++) {
                        var downlistval = forchildrn[j].downInfos;
                        if (forchildrn[j].SoRowDetailInfo.Id != null) {
                            var childrenVal = {
                                processList: forchildrn[j].processList,
                                downlist: downlistval,
                                date: getDateYYYMMDD(forchildrn[j].PoRowInfo.OrderDate),
                                vendorVal: forchildrn[j].PoRowInfo.VendorCorporationId,
                                codeVal: forchildrn[j].PoRowDetailInfo.Code,
                                POVal: forchildrn[j].PoRowInfo.Po,
                                CbrandVar: forchildrn[j].PoRowInfo.Brand,
                                CdivisionVar: forchildrn[j].PoRowInfo.DepartmentId,
                                CsupplierVar: forchildrn[j].PoRowInfo.VendorCorporationId,
                                AffiliatedCorpCollectionVar: forchildrn[j].SoRowInfo.AffiliatedCorpCollectionName,
                                AffiliatedCorpWorkingVar: forchildrn[j].SoRowInfo.AffiliatedCorpWorkingName,
                                isCollection: forchildrn[j].SoRowInfo.AffiliatedCorpCollectionName != null ? true : false,
                                isWorking: forchildrn[j].SoRowInfo.AffiliatedCorpWorkingName != null ? true : false,
                                CorporationId: forchildrn[j].SoRowInfo.CorporationId,
                                AffiliatedCorpCollectionId: forchildrn[j].SoRowInfo.AffiliatedCorpCollection,
                                AffiliatedCorpWorkingId: forchildrn[j].SoRowInfo.AffiliatedCorpWorking,
                                BusinessNoInVar: forchildrn[j].SoRowDetailInfo.BusinessNoIn,
                                BusinessNoOutVar: forchildrn[j].SoRowDetailInfo.BusinessNoOut,
                                title: forchildrn[j].PoRowDetailInfo.Batch,
                                CproductVar: forchildrn[j].PoRowDetailInfo.ProductName,
                                compositionVar: forchildrn[j].Component,
                                CproductCodeVar: forchildrn[j].PoRowDetailInfo.Code,
                                CbatchVar: forchildrn[j].PoRowDetailInfo.Batch,
                                CcolorVar: forchildrn[j].PoRowDetailInfo.Color,
                                CnunberVar: forchildrn[j].PoRowDetailInfo.Number,
                                CunitVar: forchildrn[j].PoRowDetailInfo.UnitId,
                                poDetailVar: forchildrn[j].SoRowDetailInfo.Id,
                                collapseName: forchildrn[j].SoRowDetailInfo.Id,
                                statusColorVar: forchildrn[j].StatusColor,
                                statusVar: forchildrn[j].StatusCompletion,
                                statuslist: forchildrn[j].StatusList,
                                ApproveColorVar: forchildrn[j].ApproveColor,
                                ApproveImgVar: forchildrn[j].ApproveImg,
                                ApproveVar: forchildrn[j].ApproveInfo,
                                Approvelist: forchildrn[j].ApproveList,
                                isRawFiberTypeId: forchildrn[j].PoRowDetailInfo.RawFiberTypeName != null ? true : false,
                                RawFiberTypeIdVar: forchildrn[j].PoRowDetailInfo.RawFiberTypeName,
                                isVendorAddressId: forchildrn[j].PoRowDetailInfo.VendorAddressId != "" ? true : false,
                                VendorAddressIdVar: forchildrn[j].PoRowDetailInfo.VendorAddressId,
                                LockedStatusVar: forchildrn[j].SoRowDetailInfo.LockedStatus,
                                ApproveStatusVar: forchildrn[j].SoRowDetailInfo.ApproveStatus,
                                isCantApproveVar: forchildrn[j].SoRowDetailInfo.ApproveStatus > 300 ? false : true,
                                isLastVar: false,
                                VendorSectionSortVar: forchildrn[j].VendorSectionSort,
                                IsHavePending: forchildrn[j].IsHavePending,
                                RelatedId: forchildrn[j].RelatedId,
                                fileCount: forchildrn[j].fileCount,
                            }
                            if (forchildrn[j].SoRowDetailInfo.ApproveStatus < 320) {
                                this.isAllSubAppved = false;
                            }
                            childrenlistVar.push(childrenVal);
                        } else {
                            var childrVal = {
                                // fileList: forchildrn[j].fileList,
                                processList: forchildrn[j].processList,
                                downlist: downlistval,
                                date: getDateYYYMMDD(forchildrn[j].PoRowInfo.OrderDate),
                                vendorVal: forchildrn[j].PoRowInfo.VendorCorporationId,
                                codeVal: forchildrn[j].PoRowDetailInfo.Code,
                                CbrandVar: forchildrn[j].PoRowInfo.Brand,
                                CdivisionVar: forchildrn[j].PoRowInfo.DepartmentId,
                                CsupplierVar: forchildrn[j].PoRowInfo.VendorCorporationId,
                                POVal: forchildrn[j].PoRowInfo.Po,
                                AffiliatedCorpCollectionVar: "",
                                AffiliatedCorpWorkingVar: "",
                                CorporationId: "",
                                AffiliatedCorpCollectionId: "",
                                AffiliatedCorpWorkingId: "",
                                isCollection: "",
                                isWorking: "",
                                BusinessNoInVar: "",
                                BusinessNoOutVar: "",
                                title: forchildrn[j].PoRowDetailInfo.Batch,
                                CproductVar: forchildrn[j].PoRowDetailInfo.ProductName,
                                compositionVar: forchildrn[j].Component,
                                CproductCodeVar: forchildrn[j].PoRowDetailInfo.Code,
                                CbatchVar: forchildrn[j].PoRowDetailInfo.Batch,
                                CcolorVar: forchildrn[j].PoRowDetailInfo.Color,
                                CnunberVar: forchildrn[j].PoRowDetailInfo.Number,
                                CunitVar: forchildrn[j].PoRowDetailInfo.UnitId,
                                poDetailVar: forchildrn[j].PoRowDetailInfo.Id,
                                collapseName: forchildrn[j].PoRowDetailInfo.Id,
                                statusColorVar: forchildrn[j].StatusColor,
                                statusVar: forchildrn[j].StatusCompletion,
                                statuslist: forchildrn[j].StatusList,
                                ApproveColorVar: forchildrn[j].ApproveColor,
                                ApproveImgVar: forchildrn[j].ApproveImg,
                                ApproveVar: forchildrn[j].ApproveInfo,
                                Approvelist: forchildrn[j].ApproveList,
                                isRawFiberTypeId: forchildrn[j].PoRowDetailInfo.RawFiberTypeName != null ? true : false,
                                RawFiberTypeIdVar: forchildrn[j].PoRowDetailInfo.RawFiberTypeName,
                                isVendorAddressId: forchildrn[j].PoRowDetailInfo.VendorAddressId != "" ? true : false,
                                VendorAddressIdVar: forchildrn[j].PoRowDetailInfo.VendorAddressId,
                                LockedStatusVar: forchildrn[j].PoRowDetailInfo.LockedStatus,
                                ApproveStatusVar: forchildrn[j].PoRowDetailInfo.ApproveStatus,
                                isCantApproveVar: forchildrn[j].SoRowDetailInfo.ApproveStatus > 300 ? false : true,
                                isLastVar: true,
                                VendorSectionSortVar: forchildrn[j].VendorSectionSort,
                                IsHavePending: forchildrn[j].IsHavePending,
                                RelatedId: forchildrn[j].RelatedId,
                                fileCount: forchildrn[j].fileCount,
                            }
                            childrenlistVar.push(childrVal);
                        }
                    }
                    var eachStagePOVal = { title: eachspo[i].title, children: childrenlistVar };
                    eachspolist.push(eachStagePOVal);
                    this.InfoData.activities.push(eachStagePOVal);
                }
                //生成二维码
                this.qrpocode = res.data.PoQRCode;
                this.qrColorCode = res.data.PoQRColor;
            }).catch((erro) => {
                window.console.log(erro)
            });
            this.currentTime = getDate(new Date());
        },
        querySearchCodeAsync(queryString, cb) {
            var Coderestaurants = this.Coderestaurants;
            var results = queryString ? Coderestaurants.filter(this.createStateFilterCode(queryString)) : Coderestaurants;
            clearTimeout(this.Codetimeout);
            this.Codetimeout = setTimeout(() => {
                cb(results.slice(0, 10));
            }, 1000 * Math.random());
        },
        createStateFilterCode(queryString) {
            return (Codestate) => {
                return (Codestate.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleChange() {
            this.getPurOrdList();
        },
        handleSelect() {
            this.getPurOrdList();
        },//选中批号
        async getPurOrdList() {
            await PoAndDetailList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.codeVal, this.poVal, this.colorVal, this.no_SeaCodeVal, this.SeaCodeVal, this.beginTimeVal, this.endTimeVal,).then(res => {
                var stockListInfo = res.data.OrderStocks;
                if (stockListInfo != null) {
                    this.potable = [];
                    for (var i = 0; i < stockListInfo.length; i++) {
                        this.potable.push({
                            id: stockListInfo[i].POInfoDetail.Id,
                            poDetailId: stockListInfo[i].POInfoDetail.Id,
                            purchaseOrderId: stockListInfo[i].POInfoDetail.PurchaseOrderId,
                            productName: stockListInfo[i].POInfoDetail.ProductName,
                            orderDate: stockListInfo[i].POInfo.OrderDate == '' ? '' : stockListInfo[i].POInfo.OrderDate.substr(0, 10),
                            po: stockListInfo[i].POInfo.Po,
                            vendorCorporationId: stockListInfo[i].POInfo.VendorCorporationId,
                            batch: stockListInfo[i].POInfoDetail.Batch,
                            color: stockListInfo[i].POInfoDetail.Color,
                            code: stockListInfo[i].POInfoDetail.Code,
                            status: stockListInfo[i].POInfoDetail.Status,
                            unitId: stockListInfo[i].POInfoDetail.UnitName,
                            number: stockListInfo[i].POInfoDetail.Number,
                        })
                    }
                }
                this.page.total = res.data.TotalCount;
                this.page.pageSize = res.data.PageSize;
                this.currentTime = getDate(new Date());
                //只有一条数据
                if (this.potable.length == 1) {
                    this.selectId = this.potable[0].poDetailId;
                    this.$router.push({ query: { ID: this.selectId } })
                    location.reload();
                } else {
                    this.dialogTableVisible = true;
                }

            }).catch(erro => {
                console.log(erro)
            })
        },
        currentChange(val) {
            this.page.currentPage = val
            this.getPurOrdList();
        },
        GetRowKeys(row) {
            return row.Id;
        },
        selectionChange(list) {
            if (list.length > 1) {
                this.$refs.crud.toggleSelection();
                this.$message({
                    message: this.$t('OnlyCheckedOne'),
                    type: "warning"
                });
            } else {
                this.selectedData = list;
            }
        },//改变项值
        submitSelectData(row) {
            this.selectId = row.poDetailId;
            this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: this.selectId } })
            this.dialogTableVisible = false;
        },
        SelectData() {
            this.no_SeaCodeVal = "no";
            this.getPurOrdList();
        },
        showFileList(e) {
            this.fileDialogTableVisible = true;
            this.fileList = e;

        },
        showField(e) {
            this.fieldDialogTableVisible = true;
            this.fieldList = e;
        },
        noline() {
            this.$message({ showClose: true, message: this.$t('online'), type: "warning" });
        },
        print() {
            //查询PDF包
            GetOrderPackage(this.loadId).then(res => {
                var o = res.data;
                if (o.status == 0) {
                    if (o.id != '') {
                        //新增操作记录
                        let paramrow = {
                            orderPackageId: o.id,
                            type: 4,
                            remark: "",
                            status: 0,
                            createUserid: '',
                            corporationId: ''
                        };
                        add(paramrow).then(() => {
                        }).catch((erro) => {
                            console.log(erro)
                        });
                        //进行打印
                        if (o.path != '') {
                            let page = window.open(o.path);
                            setTimeout(() => {
                                page.print();
                            }, 500)
                        }
                    }
                } else {
                    this.$message({ showClose: true, message: this.$t('NoPreviewedPrinting'), type: "warning" });
                }
            });

        },
        GetReview() {
            GetOrderPackage(this.loadId).then(res => {
                var o = res.data;
                if (o.status == 0) {
                    this.$router.push({ path: '/pvh_operation/review', query: { OrderId: this.loadId } })
                } else {
                    this.$router.push({ path: '/pvh_operation/PdfGenerationList', query: { OrderId: this.loadId, Speed: this.Speed } })
                }
            });
        },
        iframePic(url) {
            if (url.indexOf('.pdf') > 0 || url.indexOf('.PDF') > 0) {
                return true
            } else {
                return false
            }
        },
        pdfYl(url) {
            window.open(url)
        },
        downloadFile(id) {
            // alert(id)
            var downpath = pvhApiBase + "Tracing/GetFilePathStream?id=" + id;
            return downpath;
            this.downloadPDF(downpath, e.Name);
        },
        downloadPDF(url, fileName) {
            fetch(url).then(function (response) {
                if (response.ok) {
                    return response.arrayBuffer()
                }
                throw new Error('Network response was not ok.')
            }).then(function (arraybuffer) {
                let blob = new Blob([arraybuffer], {
                    type: `application/pdf;charset-UTF-8` // word文档为msword,pdf文档为pdf
                })
                let objectURL = URL.createObjectURL(blob)
                let downEle = document.createElement('a')
                let fname = fileName // 下载文件的名字
                // let fname = `download` // 下载文件的名字
                downEle.href = objectURL
                downEle.setAttribute('download', fname)
                document.body.appendChild(downEle)
                downEle.click()
            }).catch(function (error) {
                console.log('There has been a problem with your fetch operation: ', error.message)
            })
        },
        handleClick() {
            // if (this.activeName == "second") {
            //   this.$router.push({path: '/pvh_operation/orderTree', query: {ID: this.loadId}})
            // }
        },
        ApprovedsubmitForm(e) {
            var isBodyType = "isbody";
            this.$confirm(this.isAllSubAppved ? this.$t('IsDeclarationcompleted') : this.$t('NoAllSubAppvedTip'), this.$t('Please Confirm'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: "warning"
            }).then(() => {
                DecCompleted(this.loadId, e).then(() => {
                    this.init();
                    this.$message({ showClose: true, message: this.$t('OperationSuccess'), type: "success" });
                });
            })
        },
        submitDownUnlock(e) {
            let paramrow = {
                topPoDetailOrderId: this.loadId,
                detailOrderId: e.PoRowDetailInfo.Id,
                approvedObject: '',
                rejectCategory: "",
                rejectRemark: "Operation Unlock",
                remarks: '',
                status: 0,
            };
            addUnlockPo(paramrow).then(() => {
                this.$message({
                    message: this.$t('OperationSuccess'),
                    type: "success"
                });
                this.init();
            }).catch((erro) => {
                console.log(erro)
            });
        },
        recallDown(e) {
            this.$confirm(this.$t('Please confirm whether you want to revoke the rejection'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: "warning"
            }).then(() => {
                let paramrow = {
                    topPoDetailOrderId: this.loadId,
                    detailOrderId: e.PoRowDetailInfo.Id,
                    approvedObject: '',
                    remarks: '',
                    status: 420,
                };
                addRecallPo(paramrow).then(() => {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    this.init();
                }).catch((erro) => {
                    console.log(erro)
                });
            })
        },
        submitDownLock(e) {
            let paramrow = {
                topPoDetailOrderId: this.loadId,
                detailOrderId: e.PoRowDetailInfo.Id,
                approvedObject: '',
                rejectCategory: "",
                rejectRemark: "Operation Lock",
                remarks: '',
                status: 605,
            };
            addLockPo(paramrow).then(() => {
                this.$message({
                    message: this.$t('OperationSuccess'),
                    type: "success"
                });
                this.init();
            }).catch((erro) => {
                console.log(erro)
            });
        },
        RejectDownForm(e) {
            var info = e;
            this.RejectdialogTableVisible = true;
            this.RejectEdit.DetailOrderId = info.PoRowDetailInfo.Id;
            this.RejectEdit.ApprovedObject = '';
            this.RejectEdit.ApprovedObjectPO = info.PoRowInfo.Po;
            this.RejectEdit.ApprovedObjectName = info.PoRowInfo.VendorCorporationId;
            this.RejectEdit.RejectCategory = '';
            this.RejectEdit.RejectRemark = '';
            this.RejectEdit.isPo = true;
        },
        ApprovalHistoryDown(e) {
            var info = e;
            this.ApprovalHistorydialogTableVisible = true;
            this.RejectEdit.DetailOrderId = info.PoRowDetailInfo.Id;
            this.getRejectList();
        },
        ApprovalHistory(e) {
            var info = e;
            this.ApprovalHistorydialogTableVisible = true;
            this.RejectEdit.DetailOrderId = info.poDetailVar;
            this.getRejectList();
        },
        RejectForm(e) {
            var info = e;
            this.RejectdialogTableVisible = true;
            this.RejectEdit.DetailOrderId = info.poDetailVar;
            this.RejectEdit.ApprovedObject = '';
            this.RejectEdit.ApprovedObjectPO = info.POVal;
            this.RejectEdit.ApprovedObjectName = info.vendorVal;
            this.RejectEdit.RejectCategory = '';
            this.RejectEdit.RejectRemark = '';
            this.RejectEdit.isPo = false;
        },
        recall(e) {
            this.$confirm(this.$t('Please confirm whether you want to revoke the rejection'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: "warning"
            }).then(() => {
                let paramrow = {
                    topPoDetailOrderId: this.loadId,
                    detailOrderId: e.poDetailVar,
                    approvedObject: '',
                    remarks: '',
                    status: 420,
                };
                addRecallSo(paramrow).then(() => {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    this.init();
                }).catch((erro) => {
                    console.log(erro)
                });
            })

        },
        submitUnlock(e) {
            let paramrow = {
                topPoDetailOrderId: this.loadId,
                detailOrderId: e.poDetailVar,
                approvedObject: '',
                rejectCategory: "",
                rejectRemark: "Operation Unlock",
                remarks: '',
                status: 0,
            };
            addUnlockSo(paramrow).then(() => {
                this.$message({
                    message: this.$t('OperationSuccess'),
                    type: "success"
                });
                this.init();
            }).catch((erro) => {
                console.log(erro)
            });
        },
        submitLock(e) {
            let paramrow = {
                topPoDetailOrderId: this.loadId,
                detailOrderId: e.poDetailVar,
                approvedObject: '',
                rejectCategory: "",
                rejectRemark: "Operation Lock",
                remarks: '',
                status: 605,
            };
            addLockSo(paramrow).then(() => {
                this.$message({
                    message: this.$t('OperationSuccess'),
                    type: "success"
                });
                this.init();
            }).catch((erro) => {
                console.log(erro)
            });
        },
        submitReject(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let paramrow = {
                        topPoDetailOrderId: this.loadId,
                        detailOrderId: this.RejectEdit.DetailOrderId,
                        approvedObject: '',
                        rejectCategory: this.RejectEdit.RejectCategory,
                        rejectRemark: this.RejectEdit.RejectRemark,
                        remarks: '',
                        status: 410,
                    };
                    this.isSaveDisable = true;
                    if (this.RejectEdit.isPo) {
                        setTimeout(() => {
                            addApprovesPo(paramrow).then(() => {
                                this.$message({
                                    message: this.$t('OperationSuccess'),
                                    type: "success"
                                });
                                this.RejectdialogTableVisible = false;
                                this.isSaveDisable = false;
                                this.init();
                            }).catch((erro) => {
                                console.log(erro)
                            });
                        }, 1000 * Math.random());
                    } else {
                        setTimeout(() => {
                            addApprovesSo(paramrow).then(() => {
                                this.$message({
                                    message: this.$t('OperationSuccess'),
                                    type: "success"
                                });
                                this.RejectdialogTableVisible = false;
                                this.isSaveDisable = false;
                                this.init();
                            }).catch((erro) => {
                                console.log(erro)
                            });
                        }, 1000 * Math.random());
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        async getRejectList() {
            await ListApproves(this.RejectEdit.DetailOrderId, this.pageRejectList.currentPage, this.pageRejectList.pageSize).then(res => {
                var RejectInfo = res.data.OrderApproves;
                this.tableDataRejectList = RejectInfo;
                this.pageRejectList.total = res.data.TotalCount;
                this.pageRejectList.pageSize = res.data.PageSize;
            }).catch(erro => {
                console.log(erro)
            })
        },
        currentRejectListChange(val) {
            this.pageRejectList.currentPage = val
            this.getRejectList();
        },
        RejectCategorySearch(queryString, cb) {
            var restaurants = this.RejectCategoryData;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results.slice(0, 10));
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        formatterApprove(e) {
            var helpReturn = e;
            if (helpReturn.Param1 == "Reject") {
                return "× " + this.$t('Reject') + " : " + helpReturn.Param2 + "-" + helpReturn.Param3;
            } else if (helpReturn.Param1 == "Locked") {
                return "O " + this.$t('Other operations') + " : " + helpReturn.Param2 + "-" + helpReturn.Param3;
            } else if (helpReturn.Param1 == "Resubmit") {
                return "↑ " + this.$t('Resubmit') + " : " + helpReturn.Param2;
            } else if (helpReturn.Param1 == "No Audit Record") {
                return this.$t('No Audit Record');
            } else {
                return "-";
            }
        },
        formatterColumn(e) {
            var helpReturn = e;
            if (helpReturn.Param1 == "HaveUpinfo") {
                return "√ " + helpReturn.Param2 + " : " + this.$t('HaveUpinfo');
            } else if (helpReturn.Param1 == "NoNeedUpinfo") {
                return "√" + this.$t('NoNeedUpinfo');
            } else if (helpReturn.Param1 == "NoUpinfo") {
                return "× " + helpReturn.Param2 + " : " + this.$t('NoUpinfo');
            } else if (helpReturn.Param1 == "NoWriteSO") {
                return "×" + this.$t('NoWriteSO');
            } else if (helpReturn.Param1 == "HaveWriteSO") {
                return "√" + this.$t('HaveWriteSO');
            } else if (helpReturn.Param1 == "NoWriteComponent") {
                return "×" + this.$t('NoWriteComponent');
            } else if (helpReturn.Param1 == "HaveWriteComponent") {
                return "√" + this.$t('HaveWriteComponent');
            } else if (helpReturn.Param1 == "NoRelation") {
                return "×" + this.$t('NoRelation');
            } else if (helpReturn.Param1 == "HaveRelation") {
                return "√" + this.$t('HaveRelation');
            } else {
                return "";
            }
        },
        onPrint() {
            this.$print(this.$refs.qrCodeUrl)
        },
        GetStatusName(statusCode) {
            var s_Status = "";
            switch (statusCode) {
                case -1:
                    s_Status = this.$t('Delete');
                    break;
                case 0:
                    s_Status = this.$t('Ordering');
                    break;
                case 110:
                    s_Status = this.$t('Ordering');
                    break;
                case 120:
                    s_Status = this.$t('Ordering');
                    break;
                case 130:
                    s_Status = this.$t('Ordering');
                    break;
                case 140:
                    s_Status = this.$t('Ordering');
                    break;
                case 150:
                    s_Status = this.$t('Ordering');
                    break;
                case 160:
                    s_Status = this.$t('Under Declaration');
                    break;
                case 320:
                    s_Status = this.$t('Submitted');
                    break;
                case 410:
                    s_Status = this.$t('Rejected');
                    break;
                case 550:
                    s_Status = this.$t('Resubmitted');
                    break;
                case 605:
                    s_Status = this.$t('Locked');
                    break;
                case 660:
                    s_Status = this.$t('Approved');
                    break;
                case 670:
                    s_Status = this.$t('Reviewed');
                    break;
                default:
                    s_Status = this.$t('unknown');
            }
            return s_Status;
        },
    }
}
</script>
