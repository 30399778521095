<template>
  <div class="pvhFactory">
    <basic-container>
      <div class="layTop">
        <div v-if="activeName=='first'" class="Title" style="margin-bottom:10px;">{{ $t('PRODUCT TRACING') }}</div>
        <div v-if="activeName=='second'" class="Title" style="margin-bottom:10px;">{{ $t('PRODUCT TREE TRACING') }}</div>
        <div v-if="activeName=='ToDo'" class="Title" style="margin-bottom:10px;">{{ $t('To Do') }}</div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('PRODUCT TRACING')" name="first"></el-tab-pane>
        <el-tab-pane :label="$t('PRODUCT TREE TRACING')" name="second"></el-tab-pane>
        <el-tab-pane :label="$t('To Do')" name="ToDo"></el-tab-pane>
      </el-tabs>
      <div v-show="activeName == 'first'">
        <productTracing :FlookInfo="lookInfo"/>
      </div>
      <div v-show="activeName == 'second'">
        <OrderTree />
      </div>
      <div v-show="activeName == 'ToDo'">
        <ToDo :todoID="todoID"/>
      </div>
    </basic-container>
  </div>
</template>
<style scoped>
.el-dialog__wrapper {
  transition-duration: 0.3s;
}

.tracingList .el-timeline-item {
  padding-left: 110px;
  position: relative;
}

.tracingList .el-timeline-item__timestamp {
  position: absolute;
  left: -110px;
  top: 0;
  margin-top: 2px;
}

.tracingList .el-timeline-item__tail {
  position: absolute;
  left: 114px !important;
  top: 2px !important;
}

.tracingList .el-timeline-item__node {
  position: absolute;
  left: 109px !important;
  top: 0 !important;
}

.topTitleCon {
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
  font-weight: 700;
}

.topTitleCon i {
  vertical-align: 1px;
  font-size: 13px !important;
  margin-right: 15px;
  -webkit-font-smoothing: antialiased;
}

.topTitleCon2 {
  font-size: 12px;
  color: #333;
  padding-left: 3px;
  box-sizing: border-box;
  font-weight: 500;
}

.topTitleCon2 i {
  vertical-align: 1px;
  font-size: 13px !important;
  margin-right: 15px;
  -webkit-font-smoothing: antialiased;
}

.downIcon i {
  margin: 0 !important;
  margin-top: -2px !important;
}

.downIcon {
  color: #409EFF;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  border-color: #409EFF;
  padding: 0 !important;
}

.tli {
  border: 1px dashed #979797;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.tli .el-collapse-item__header {
  height: 35px;
  background-color: #ebeef5;
}

.tli .el-timeline-item {
  padding-bottom: 1px;
}

.upTit {
  font-size: 8px;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #f5f7fa;
  font-weight: 700;
  color: #ccc;
}

.colInfo {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.downListCon {
  border-bottom: 2px solid #EBEEF5;
  /*  border: .0425rem dashed #979797;
background-color: rgb(253 249 242);*/
}

.fontTitle {
  font-weight: 700;
  font-size: 14px;
  background: #ecf5ff7d;
}

.fontCont {
  font-size: 0.65rem;
  color: #303133;
}

.fontSumCont {
  font-size: 1rem;
  FONT-WEIGHT: 800;
  color: #333;
}

.abbreviated-file {
  padding-bottom: 20px;
}

.abbreviated-file .file {
  width: 100px;
  height: 160px;
  border: 1px dashed #eee;
}

.abbreviated-file .file iframe {
  width: 100%;
  border: none;
  height: 100%;
  overflow: hidden;
}
</style>
<script>
import { AllPOList, getDate, getDateYYYMMDD, PoAndDetailList, DecCompleted } from "@/api/purchaseOrd";
import { TracingUnifiedIsBool, TracingDropDownList, TracingStringList } from "@/api/unified";
import { add, GetOrderPackage } from "@/api/orderPackage";
import OrderTree from './orderTree';
import productTracing from './productTracing.vue'
import {
  ListApproves,
  addApprovesSo,
  addUnlockSo,
  addLockSo,
  addApprovesPo,
  addUnlockPo,
  addLockPo,
} from "@/api/orderApprove";
import { pvhApiBase } from '@/config/env'
import VueQr from 'vue-qr'
import ToDo from "@/views/pvh_operation/toDo";

export default {
  data() {
    return {
      todoID: '',
      activeName: 'first',
      isBrandBusiness: false,
      isBrandBusiness_top: false,
      isAdvancedSearch: false,
      codeVal: "",
      poVal: "",
      colorVal: "",
      beginTimeVal: "",
      endTimeVal: "",
      vendorVal: { ids: [], },
      vendorData: [],
      businessNoInVal: '',
      businessNoOutVal: '',
      loadId: '',
      qrpocode: '',
      qrColorCode: '',
      currentTime: "",
      Coderestaurants: [],
      Codestate: '',
      Codetimeout: null,
      potable: [],
      codeslist: [],
      selectedData: [],//采购商
      bottomInfo: {
        TotalNoApprove: 0, TotalSuppliers: 0, TotalPo: 0, TotalSubmissionTimes: 0, TotalDocuments: 0,
      },
      topInfo: {
        brandVal: '',
        departVal: '',
        productVal: '',
        supplierVal: '',
        codeVal: '',
        poVal: '',
        batchVal: '',
        contractNoVal: '',
        IngredientVal: '',
        productNameVal: '',
        colorVal: '',
        numberVal: '',
        unitVal: '',
        destinationCountryNameVal: '',
        TrackingApproveStatusVal: 0,
      },
      InfoData: {
        activities: [{
          title: '',
          children: [{
            date: '',
            vendorVal: '',
            codeVal: '',
            POVal: '',
            processList: [],
            processCount: '',
            fileCount: '',
            fileList: [{ name: '', fileURL: '' }],
            downlist: [],
            title: '',
            CbrandVar: '',
            CdivisionVar: '',
            CsupplierVar: '',
            CproductVar: '',
            CproductCodeVar: '',
            CbatchVar: '',
            CcolorVar: '',
            CnunberVar: '',
            CunitVar: ''
            ,
            compositionVar: '',
            isCollection: false,
            isWorking: false,
            AffiliatedCorpCollectionVar: "",
            AffiliatedCorpWorkingVar: "",
            CorporationId: "",
            AffiliatedCorpCollectionId: "",
            AffiliatedCorpWorkingId: "",
            BusinessNoInVar: "",
            BusinessNoOutVar: "",
            LossRateVar: 0,
            isRawFiberTypeId: false,
            RawFiberTypeIdVar: "",
            isVendorAddressId: false,
            VendorAddressIdVar: "",
            LockedStatusVar: 0,
            ApproveStatusVar: 0,
            isLastVar: false,
            VendorSectionSortVar: 0,
            poDetailVar: '',
            statusColorVar: 'info',
            statusVar: '',
            statuslist: [],
            collapseName: [],
            ApproveImgVar: '',
            ApproveColorVar: 'info',
            ApproveVar: '',
            Approvelist: [],
          }]
        }]
      },
      no_SeaCodeVal: 'no',
      selectId: '',
      SeaCodeVal: '',
      dialogTableVisible: false,
      fileDialogTableVisible: false,
      fileList: [], //当前查询值
      fieldDialogTableVisible: false,
      fieldList: [],
      RejectdialogTableVisible: false,
      tableDataRejectList: [],
      RejectCategoryData: [],
      RejectEdit: {
        DetailOrderId: '',
        ApprovedObject: '',
        ApprovedObjectName: '',
        RejectCategory: '',
        RejectRemark: '',
        isPo: true,
      },
      rules: {
        RejectCategory: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
        RejectRemark: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
      },
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      pageRejectList: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableOpt: {
        page: true,
        refreshBtn: false,
        columnBtn: false,
        simplePage: true,
        addBtn: false,
        addRowBtn: false,
        cellBtn: false,
        index: true,
        indexLabel: ' ',
        border: true,
        align: "center",
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        menuWidth: 200,
        editBtn: false,
        delBtn: false,
        //selection: true,
        //reserveSelection: true,
        tip: false,
        column: [
          {
            label: this.$t('DATE'),
            prop: 'orderDate',
            type: "date",
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
          },
          {
            label: this.$t('VENDOR'),
            prop: 'vendorCorporationId',
          },
          {
            label: this.$t('ProductCode'),
            prop: 'code',
          },
          {
            label: this.$t('Product Name'),
            prop: 'productName',
          },
          {
            label: this.$t('BATCH'),
            prop: 'batch',
          },
          {
            label: this.$t('COLOR'),
            prop: 'color',
          },
          {
            label: this.$t('PO'),
            prop: 'po',
          },
          {
            label: this.$t('UNIT'),
            prop: 'unitId',
          },
          {
            label: this.$t('OrderNumber'),
            prop: 'number',
          }
        ]
      },
      filetableOpt: {
        page: false,
        refreshBtn: false,
        columnBtn: false,
        simplePage: true,
        addBtn: false,
        addRowBtn: false,
        editBtn: false,
        delBtn: false,
        cellBtn: false,
        index: true,
        indexLabel: ' ',
        border: true,
        align: "center",
        menuTitle: this.$t('OPERATION'),
        emptyText: this.$t('No Data'),
        tip: false,
        column: [
          {
            label: this.$t('PROCESS NAME'),
            prop: 'ProcessName',
          },
          {
            label: this.$t('FILE TYPE'),
            prop: 'SupportFileName',
          },
          {
            label: this.$t('FILE NAME'),
            prop: 'Name',
          },
          {
            label: this.$t('Index'),
            prop: 'FileURL',
            hide: true,
          },
        ]
      },
      tableOptRejectList: {
        page: false,
        refreshBtn: false,
        columnBtn: false,
        simplePage: true,
        addBtn: false,
        addRowBtn: false,
        editBtn: false,
        delBtn: false,
        cellBtn: false,
        index: true,
        menu: false,
        indexLabel: ' ',
        border: true,
        align: "center",
        emptyText: this.$t('No Data'),
        tip: false,
        column: [
          {
            label: this.$t('DATE'),
            prop: 'CreateTime',
            type: "date",
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
          },
          {
            label: this.$t('ORDERS COMPANY'),
            prop: 'ApprovedObject',
          },
          {
            label: this.$t('REJECTION CATEGORY'),
            prop: 'RejectCategory',
          },
          {
            label: this.$t('REJECTION DESCRIPTION'),
            prop: 'RejectRemark',
          },
          {
            label: this.$t('RESUBMISSION INSTRUCTIONS'),
            prop: 'Remarks',
          },
        ]
      }
    }
  },
  mounted() {

    this.init();// 在加载的时候执行了这个方法，可以调用这个根据ID来插叙的方法
    this.Coderestaurants = this.getCodeList();//数据源
  },
  components: {
    ToDo,
    VueQr,
    OrderTree,
    productTracing
  },
  watch: {
    $route() {
      this.init()
    }
  },
  methods: {
    lookInfo(type, id) {
      this.activeName = type;
      this.todoID = id;
    },
    //设置表头行的样式
    tableHeaderColor() {
      return 'background-color:#838bb3!important;padding: 0!important;'

    },//表格行央视
    rowStyle({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
    moreFrom() {
      let fromHei = document.querySelector(".fromOutDiv").clientHeight;
      if (fromHei > 60) {
        document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
      } else {
        document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
      }
    },  //点击更多按钮
    focusDiv(obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      } else {
        if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
          obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        } else {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        }

      }
    },//搜索框失去焦点
    blurDiv(obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === "") {
          obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else if (obj.currentTarget === null) {
        if (this.vendorVal.ids.length === 0) {
          document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else {
        if (obj.target.value == "") {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      }
    },
    venchang() {
      if (this.vendorVal.ids.length === 0) {
        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
      } else {
        document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//点击更多按钮
    searchFrom() {
      this.no_SeaCodeVal = "yes";
      this.getPurOrdList();
    },//查询
    getCodeList() {
      this.Codeslist = [];
      TracingStringList("ALLPurchaseOrderInfo").then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.Codeslist.push({
            value: res.data[i]
          })
        }
      })
      return this.Codeslist;
    },
    init() {
      this.loadId = this.$route.query.ID;
      TracingDropDownList('VendorList').then(res => {
        this.vendorData = res.data;
      });
      TracingUnifiedIsBool("OrderIsBrandBusiness", this.loadId).then(res => {
        this.isBrandBusiness = res.data;
      });
      TracingUnifiedIsBool("IsBrandBusiness").then(res => {
        this.isBrandBusiness_top = res.data;
      });
      AllPOList(this.loadId).then(res => {
        //底部
        this.bottomInfo.TotalOrderProgress = res.data.TotalOrderProgress;
        this.bottomInfo.TotalNoApprove = res.data.TotalNoApprove;
        this.bottomInfo.TotalSuppliers = res.data.TotalSuppliers;
        this.bottomInfo.TotalPo = res.data.TotalPo;
        this.bottomInfo.TotalSubmissionTimes = res.data.TotalSubmissionTimes;
        this.bottomInfo.TotalDocuments = res.data.TotalDocuments;
        //头部
        this.topInfo.supplierVal = res.data.TopPoInfo.VendorCorporationId;
        this.topInfo.codeVal = res.data.TopPoDetailInfo.Code;
        this.topInfo.brandVal = res.data.TopPoInfo.Brand;
        this.topInfo.departVal = res.data.TopPoInfo.DepartmentId;
        this.topInfo.productVal = res.data.TopPoDetailInfo.ProductName;
        this.topInfo.poVal = res.data.TopPoInfo.Po;
        this.topInfo.batchVal = res.data.TopPoDetailInfo.Batch;
        this.topInfo.contractNoVal = res.data.TopPoInfo.ContractId;
        this.topInfo.IngredientVal = res.data.TopPoDetailInfo.Ingredient;
        this.topInfo.productNameVal = res.data.TopPoDetailInfo.ProductName;
        this.topInfo.colorVal = res.data.TopPoDetailInfo.Color;
        this.topInfo.numberVal = res.data.TopPoDetailInfo.Number;
        this.topInfo.destinationCountryNameVal = res.data.TopPoDetailInfo.DestinationCountryName;
        this.topInfo.unitVal = res.data.TopPoDetailInfo.UnitId;
        this.topInfo.TrackingApproveStatusVal = res.data.TopPoDetailInfo.TrackingApproveStatus;
        var eachspo = [];
        var forchildrn = [];
        var eachspolist = [];
        this.InfoData.activities = [];
        eachspo = res.data.eachStagePOList;
        for (var i = 0; i < eachspo.length; i++) {
          forchildrn = eachspo[i].children;
          var childrenlistVar = [];
          for (var j = 0; j < forchildrn.length; j++) {
            var downlistval = forchildrn[j].downInfos;
            if (forchildrn[j].SoRowDetailInfo.Id != null) {
              var childrenVal = {
                fileList: forchildrn[j].fileList,
                processList: forchildrn[j].processList,
                processCount: forchildrn[j].processCount,
                fileCount: forchildrn[j].fileCount,
                downlist: downlistval,
                date: getDateYYYMMDD(forchildrn[j].PoRowInfo.OrderDate),
                vendorVal: forchildrn[j].PoRowInfo.VendorCorporationId,
                codeVal: forchildrn[j].PoRowDetailInfo.Code,
                POVal: forchildrn[j].PoRowInfo.Po,
                CbrandVar: forchildrn[j].PoRowInfo.Brand,
                CdivisionVar: forchildrn[j].PoRowInfo.DepartmentId,
                CsupplierVar: forchildrn[j].PoRowInfo.VendorCorporationId,
                AffiliatedCorpCollectionVar: forchildrn[j].SoRowInfo.AffiliatedCorpCollectionName,
                AffiliatedCorpWorkingVar: forchildrn[j].SoRowInfo.AffiliatedCorpWorkingName,
                isCollection: forchildrn[j].SoRowInfo.AffiliatedCorpCollectionName != null ? true : false,
                isWorking: forchildrn[j].SoRowInfo.AffiliatedCorpWorkingName != null ? true : false,
                CorporationId: forchildrn[j].SoRowInfo.CorporationId,
                AffiliatedCorpCollectionId: forchildrn[j].SoRowInfo.AffiliatedCorpCollection,
                AffiliatedCorpWorkingId: forchildrn[j].SoRowInfo.AffiliatedCorpWorking,
                BusinessNoInVar: forchildrn[j].SoRowDetailInfo.BusinessNoIn,
                BusinessNoOutVar: forchildrn[j].SoRowDetailInfo.BusinessNoOut,
                title: forchildrn[j].PoRowDetailInfo.Batch,
                CproductVar: forchildrn[j].PoRowDetailInfo.ProductName,
                compositionVar: forchildrn[j].Component,
                CproductCodeVar: forchildrn[j].PoRowDetailInfo.Code,
                CbatchVar: forchildrn[j].PoRowDetailInfo.Batch,
                CcolorVar: forchildrn[j].PoRowDetailInfo.Color,
                CnunberVar: forchildrn[j].PoRowDetailInfo.Number,
                CunitVar: forchildrn[j].PoRowDetailInfo.UnitId,
                poDetailVar: forchildrn[j].SoRowDetailInfo.Id,
                collapseName: forchildrn[j].SoRowDetailInfo.Id,
                statusColorVar: forchildrn[j].StatusColor,
                statusVar: forchildrn[j].StatusCompletion,
                statuslist: forchildrn[j].StatusList,
                ApproveColorVar: forchildrn[j].ApproveColor,
                ApproveImgVar: forchildrn[j].ApproveImg,
                ApproveVar: forchildrn[j].ApproveInfo,
                Approvelist: forchildrn[j].ApproveList,
                isRawFiberTypeId: forchildrn[j].PoRowDetailInfo.RawFiberTypeName != null ? true : false,
                RawFiberTypeIdVar: forchildrn[j].PoRowDetailInfo.RawFiberTypeName,
                isVendorAddressId: forchildrn[j].PoRowDetailInfo.VendorAddressId != "" ? true : false,
                VendorAddressIdVar: forchildrn[j].PoRowDetailInfo.VendorAddressId,
                LockedStatusVar: forchildrn[j].SoRowDetailInfo.LockedStatus,
                ApproveStatusVar: forchildrn[j].SoRowDetailInfo.ApproveStatus,
                isLastVar: false,
                VendorSectionSortVar: forchildrn[j].VendorSectionSort,
              }

              childrenlistVar.push(childrenVal);
            } else {
              var childrVal = {
                fileList: forchildrn[j].fileList,
                processList: forchildrn[j].processList,
                downlist: downlistval,
                date: getDateYYYMMDD(forchildrn[j].PoRowInfo.OrderDate),
                vendorVal: forchildrn[j].PoRowInfo.VendorCorporationId,
                codeVal: forchildrn[j].PoRowDetailInfo.Code,
                CbrandVar: forchildrn[j].PoRowInfo.Brand,
                CdivisionVar: forchildrn[j].PoRowInfo.DepartmentId,
                CsupplierVar: forchildrn[j].PoRowInfo.VendorCorporationId,
                AffiliatedCorpCollectionVar: "",
                AffiliatedCorpWorkingVar: "",
                CorporationId: "",
                AffiliatedCorpCollectionId: "",
                AffiliatedCorpWorkingId: "",
                isCollection: "",
                isWorking: "",
                BusinessNoInVar: "",
                BusinessNoOutVar: "",
                title: forchildrn[j].PoRowDetailInfo.Batch,
                CproductVar: forchildrn[j].PoRowDetailInfo.ProductName,
                compositionVar: forchildrn[j].Component,
                CproductCodeVar: forchildrn[j].PoRowDetailInfo.Code,
                CbatchVar: forchildrn[j].PoRowDetailInfo.Batch,
                CcolorVar: forchildrn[j].PoRowDetailInfo.Color,
                CnunberVar: forchildrn[j].PoRowDetailInfo.Number,
                CunitVar: forchildrn[j].PoRowDetailInfo.UnitId,
                poDetailVar: forchildrn[j].PoRowDetailInfo.Id,
                collapseName: forchildrn[j].PoRowDetailInfo.Id,
                statusColorVar: forchildrn[j].StatusColor,
                statusVar: forchildrn[j].StatusCompletion,
                statuslist: forchildrn[j].StatusList,
                ApproveColorVar: forchildrn[j].ApproveColor,
                ApproveImgVar: forchildrn[j].ApproveImg,
                ApproveVar: forchildrn[j].ApproveInfo,
                Approvelist: forchildrn[j].ApproveList,
                isRawFiberTypeId: forchildrn[j].PoRowDetailInfo.RawFiberTypeName != null ? true : false,
                RawFiberTypeIdVar: forchildrn[j].PoRowDetailInfo.RawFiberTypeName,
                isVendorAddressId: forchildrn[j].PoRowDetailInfo.VendorAddressId != "" ? true : false,
                VendorAddressIdVar: forchildrn[j].PoRowDetailInfo.VendorAddressId,
                LockedStatusVar: forchildrn[j].PoRowDetailInfo.LockedStatus,
                ApproveStatusVar: forchildrn[j].PoRowDetailInfo.ApproveStatus,
                isLastVar: true,
                VendorSectionSortVar: forchildrn[j].VendorSectionSort,
              }
              childrenlistVar.push(childrVal);
            }
          }
          var eachStagePOVal = { title: eachspo[i].title, children: childrenlistVar };
          eachspolist.push(eachStagePOVal);
          this.InfoData.activities.push(eachStagePOVal);
        }
        //生成二维码
        this.qrpocode = res.data.PoQRCode;
        this.qrColorCode = res.data.PoQRColor;
      }).catch((erro) => {
        window.console.log(erro)
      });
      this.currentTime = getDate(new Date());
    },
    querySearchCodeAsync(queryString, cb) {
      var Coderestaurants = this.Coderestaurants;
      var results = queryString ? Coderestaurants.filter(this.createStateFilterCode(queryString)) : Coderestaurants;
      clearTimeout(this.Codetimeout);
      this.Codetimeout = setTimeout(() => {
        cb(results.slice(0, 10));
      }, 1000 * Math.random());
    },
    createStateFilterCode(queryString) {
      return (Codestate) => {
        return (Codestate.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleChange() {
      this.getPurOrdList();
    },
    handleSelect() {
      this.getPurOrdList();
    },//选中批号
    async getPurOrdList() {
      await PoAndDetailList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.codeVal, this.poVal, this.colorVal, this.no_SeaCodeVal, this.SeaCodeVal, this.beginTimeVal, this.endTimeVal,).then(res => {
        var stockListInfo = res.data.OrderStocks;
        if (stockListInfo != null) {
          this.potable = [];
          for (var i = 0; i < stockListInfo.length; i++) {
            this.potable.push({
              id: stockListInfo[i].POInfoDetail.Id,
              poDetailId: stockListInfo[i].POInfoDetail.Id,
              purchaseOrderId: stockListInfo[i].POInfoDetail.PurchaseOrderId,
              productName: stockListInfo[i].POInfoDetail.ProductName,
              orderDate: stockListInfo[i].POInfo.OrderDate == '' ? '' : stockListInfo[i].POInfo.OrderDate.substr(0, 10),
              po: stockListInfo[i].POInfo.Po,
              vendorCorporationId: stockListInfo[i].POInfo.VendorCorporationId,
              batch: stockListInfo[i].POInfoDetail.Batch,
              color: stockListInfo[i].POInfoDetail.Color,
              code: stockListInfo[i].POInfoDetail.Code,
              status: stockListInfo[i].POInfoDetail.Status,
              unitId: stockListInfo[i].POInfoDetail.UnitName,
              number: stockListInfo[i].POInfoDetail.Number,
            })
          }
        }
        this.page.total = res.data.TotalCount;
        this.page.pageSize = res.data.PageSize;
        this.currentTime = getDate(new Date());
        //只有一条数据
        if (this.potable.length == 1) {
          this.selectId = this.potable[0].poDetailId;
          this.$router.push({ query: { ID: this.selectId } })
          location.reload();
        } else {
          this.dialogTableVisible = true;
        }

      }).catch(erro => {
        console.log(erro)
      })
    },
    currentChange(val) {
      this.page.currentPage = val
      this.getPurOrdList();
    },
    GetRowKeys(row) {
      return row.Id;
    },
    selectionChange(list) {
      if (list.length > 1) {
        this.$refs.crud.toggleSelection();
        this.$message({
          message: this.$t('OnlyCheckedOne'),
          type: "warning"
        });
      } else {
        this.selectedData = list;
      }
    },//改变项值
    submitSelectData(row) {
      this.selectId = row.poDetailId;
      this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: this.selectId } })
      this.dialogTableVisible = false;
    },
    SelectData() {
      this.no_SeaCodeVal = "no";
      this.getPurOrdList();
    },
    showFileList(e) {
      this.fileDialogTableVisible = true;
      this.fileList = e;

    },
    showField(e) {
      this.fieldDialogTableVisible = true;
      this.fieldList = e;
    },
    noline() {
      this.$message({ showClose: true, message: this.$t('online'), type: "warning" });
    },
    print() {
      //查询PDF包
      GetOrderPackage(this.loadId).then(res => {
        var o = res.data;
        if (o.status == 0) {
          if (o.id != '') {
            //新增操作记录
            let paramrow = {
              orderPackageId: o.id,
              type: 4,
              remark: "",
              status: 0,
              createUserid: '',
              corporationId: ''
            };
            add(paramrow).then(() => {
            }).catch((erro) => {
              console.log(erro)
            });
            //进行打印
            if (o.path != '') {
              let page = window.open(o.path);
              setTimeout(() => {
                page.print();
              }, 500)
            }
          }
        } else {
          this.$message({ showClose: true, message: this.$t('NoPreviewedPrinting'), type: "warning" });
        }
      });

    },
    GetReview() {
      GetOrderPackage(this.loadId).then(res => {
        var o = res.data;
        if (o.status == 0) {
          this.$router.push({ path: '/pvh_operation/review', query: { OrderId: this.loadId } })
        } else {

          this.$router.push({ path: '/pvh_operation/PdfGenerationList', query: { OrderId: this.loadId } })
        }
      });
    },
    downloadFile(e) {
      var downpath = pvhApiBase + "Tracing/GetFilePathStream?id=" + e.FileURL;
      this.downloadPDF(downpath, e.Name);
    },
    downloadPDF(url, fileName) {
      fetch(url).then(function (response) {
        if (response.ok) {
          return response.arrayBuffer()
        }
        throw new Error('Network response was not ok.')
      }).then(function (arraybuffer) {
        let blob = new Blob([arraybuffer], {
          type: `application/pdf;charset-UTF-8` // word文档为msword,pdf文档为pdf
        })
        let objectURL = URL.createObjectURL(blob)
        let downEle = document.createElement('a')
        let fname = fileName // 下载文件的名字
        // let fname = `download` // 下载文件的名字
        downEle.href = objectURL
        downEle.setAttribute('download', fname)
        document.body.appendChild(downEle)
        downEle.click()
      }).catch(function (error) {
        console.log('There has been a problem with your fetch operation: ', error.message)
      })
    },
    handleClick() {
      // if (this.activeName == "second") {
      //   this.$router.push({path: '/pvh_operation/orderTree', query: {ID: this.loadId}})
      // }
    },
    ApprovedsubmitForm(e) {
      this.$confirm(this.$t('IsDeclarationcompleted'), this.$t('Please Confirm'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: "warning"
      }).then(() => {
        DecCompleted(this.loadId, e).then(() => {
          this.init();
          this.$message({ showClose: true, message: this.$t('OperationSuccess'), type: "success" });
        });
      })
    },
    submitDownUnlock(e) {
      let paramrow = {
        topPoDetailOrderId: this.loadId,
        detailOrderId: e.PoRowDetailInfo.Id,
        approvedObject: '',
        rejectCategory: "",
        rejectRemark: "Operation Unlock",
        remarks: '',
        status: 0,
      };
      addUnlockPo(paramrow).then(() => {
        this.$message({
          message: this.$t('OperationSuccess'),
          type: "success"
        });
        this.init();
      }).catch((erro) => {
        console.log(erro)
      });
    },
    submitDownLock(e) {
      let paramrow = {
        topPoDetailOrderId: this.loadId,
        detailOrderId: e.PoRowDetailInfo.Id,
        approvedObject: '',
        rejectCategory: "",
        rejectRemark: "Operation Lock",
        remarks: '',
        status: 0,
      };
      addLockPo(paramrow).then(() => {
        this.$message({
          message: this.$t('OperationSuccess'),
          type: "success"
        });
        this.init();
      }).catch((erro) => {
        console.log(erro)
      });
    },
    RejectDownForm(e) {
      var info = e;
      this.RejectdialogTableVisible = true;
      this.RejectEdit.DetailOrderId = info.PoRowDetailInfo.Id;
      this.RejectEdit.ApprovedObject = '';
      this.RejectEdit.ApprovedObjectName = info.PoRowInfo.VendorCorporationId;
      this.RejectEdit.RejectCategory = '';
      this.RejectEdit.RejectRemark = '';
      this.RejectEdit.isPo = true;
      this.getRejectList();
    },
    RejectForm(e) {
      var info = e;
      this.RejectdialogTableVisible = true;
      this.RejectEdit.DetailOrderId = info.poDetailVar;
      this.RejectEdit.ApprovedObject = '';
      this.RejectEdit.ApprovedObjectName = info.vendorVal;
      this.RejectEdit.RejectCategory = '';
      this.RejectEdit.RejectRemark = '';
      this.RejectEdit.isPo = false;
      this.getRejectList();
    },
    submitUnlock(e) {
      let paramrow = {
        topPoDetailOrderId: this.loadId,
        detailOrderId: e.poDetailVar,
        approvedObject: '',
        rejectCategory: "",
        rejectRemark: "Operation Unlock",
        remarks: '',
        status: 0,
      };
      addUnlockSo(paramrow).then(() => {
        this.$message({
          message: this.$t('OperationSuccess'),
          type: "success"
        });
        this.init();
      }).catch((erro) => {
        console.log(erro)
      });
    },
    submitLock(e) {
      let paramrow = {
        topPoDetailOrderId: this.loadId,
        detailOrderId: e.poDetailVar,
        approvedObject: '',
        rejectCategory: "",
        rejectRemark: "Operation Lock",
        remarks: '',
        status: 0,
      };
      addLockSo(paramrow).then(() => {
        this.$message({
          message: this.$t('OperationSuccess'),
          type: "success"
        });
        this.init();
      }).catch((erro) => {
        console.log(erro)
      });
    },
    submitReject() {
      let paramrow = {
        topPoDetailOrderId: this.loadId,
        detailOrderId: this.RejectEdit.DetailOrderId,
        approvedObject: '',
        rejectCategory: this.RejectEdit.RejectCategory,
        rejectRemark: this.RejectEdit.RejectRemark,
        remarks: '',
        status: 0,
      };
      if (this.RejectEdit.isPo) {
        addApprovesPo(paramrow).then(() => {
          this.$message({
            message: this.$t('OperationSuccess'),
            type: "success"
          });
          this.RejectdialogTableVisible = false;
          this.init();
        }).catch((erro) => {
          console.log(erro)
        });
      } else {
        addApprovesSo(paramrow).then(() => {
          this.$message({
            message: this.$t('OperationSuccess'),
            type: "success"
          });
          this.RejectdialogTableVisible = false;
          this.init();
        }).catch((erro) => {
          console.log(erro)
        });
      }

    },
    async getRejectList() {
      await ListApproves(this.RejectEdit.DetailOrderId, this.pageRejectList.currentPage, this.pageRejectList.pageSize).then(res => {
        var RejectInfo = res.data.OrderApproves;
        this.tableDataRejectList = RejectInfo;
        this.pageRejectList.total = res.data.TotalCount;
        this.pageRejectList.pageSize = res.data.PageSize;
      }).catch(erro => {
        console.log(erro)
      })
    },
    currentRejectListChange(val) {
      this.pageRejectList.currentPage = val
      this.getRejectList();
    },
    RejectCategorySearch(queryString, cb) {
      var restaurants = this.RejectCategoryData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results.slice(0, 10));
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    formatterApprove(e) {
      var helpReturn = e;
      if (helpReturn.Param1 == "Reject") {
        return "× " + this.$t('Reject') + " : " + helpReturn.Param2 + "-" + helpReturn.Param3;
      } else if (helpReturn.Param1 == "Locked") {
        return "O " + this.$t('Other operations') + " : " + helpReturn.Param2 + "-" + helpReturn.Param3;
      } else if (helpReturn.Param1 == "Resubmit") {
        return "↑ " + this.$t('Resubmit') + " : " + helpReturn.Param2;
      } else if (helpReturn.Param1 == "No Audit Record") {
        return this.$t('No Audit Record');
      } else {
        return "-";
      }
    },
    formatterColumn(e) {
      var helpReturn = e;
      if (helpReturn.Param1 == "HaveUpinfo") {
        return "√ " + helpReturn.Param2 + " : " + this.$t('HaveUpinfo');
      } else if (helpReturn.Param1 == "NoNeedUpinfo") {
        return "√" + this.$t('NoNeedUpinfo');
      } else if (helpReturn.Param1 == "NoUpinfo") {
        return "× " + helpReturn.Param2 + " : " + this.$t('NoUpinfo');
      } else if (helpReturn.Param1 == "NoWriteSO") {
        return "×" + this.$t('NoWriteSO');
      } else if (helpReturn.Param1 == "HaveWriteSO") {
        return "√" + this.$t('HaveWriteSO');
      } else if (helpReturn.Param1 == "NoWriteComponent") {
        return "×" + this.$t('NoWriteComponent');
      } else if (helpReturn.Param1 == "HaveWriteComponent") {
        return "√" + this.$t('HaveWriteComponent');
      } else if (helpReturn.Param1 == "NoRelation") {
        return "×" + this.$t('NoRelation');
      } else if (helpReturn.Param1 == "HaveRelation") {
        return "√" + this.$t('HaveRelation');
      } else {
        return "";
      }
    },
    onPrint() {
      this.$print(this.$refs.qrCodeUrl)
    },
    GetStatusName(statusCode) {
      var s_Status = "";
      switch (statusCode) {
        case -1:
          s_Status = this.$t('Delete');
          break;
        case 0:
          s_Status = this.$t('initial');
          break;
        case 110:
          s_Status = this.$t('initial');
          break;
        case 120:
          s_Status = this.$t('initial');
          break;
        case 130:
          s_Status = this.$t('initial');
          break;
        case 150:
          s_Status = this.$t('under order');
          break;
        case 160:
          s_Status = this.$t('under declaration');
          break;
        case 320:
          s_Status = this.$t('Pending');
          break;
        case 410:
          s_Status = this.$t('Be rejected');
          break;
        case 550:
          s_Status = this.$t('Resubmit');
          break;
        case 605:
          s_Status = this.$t('Locked');
          break;
        case 650:
          s_Status = this.$t('Part approved');
          break;
        case 660:
          s_Status = this.$t('Approved');
          break;
        case 670:
          s_Status = this.$t('Reviewed');
          break;
        default:
          s_Status = this.$t('unknown');
      }
      return s_Status;
    },
  }
}
</script>
