var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _vm.activeName == "first"
              ? _c(
                  "div",
                  {
                    staticClass: "Title",
                    staticStyle: { "margin-bottom": "10px" }
                  },
                  [_vm._v(_vm._s(_vm.$t("PRODUCT TRACING")))]
                )
              : _vm._e(),
            _vm.activeName == "second"
              ? _c(
                  "div",
                  {
                    staticClass: "Title",
                    staticStyle: { "margin-bottom": "10px" }
                  },
                  [_vm._v(_vm._s(_vm.$t("PRODUCT TREE TRACING")))]
                )
              : _vm._e(),
            _vm.activeName == "ToDo"
              ? _c(
                  "div",
                  {
                    staticClass: "Title",
                    staticStyle: { "margin-bottom": "10px" }
                  },
                  [_vm._v(_vm._s(_vm.$t("To Do")))]
                )
              : _vm._e()
          ]),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", {
                attrs: { label: _vm.$t("PRODUCT TRACING"), name: "first" }
              }),
              _c("el-tab-pane", {
                attrs: { label: _vm.$t("PRODUCT TREE TRACING"), name: "second" }
              }),
              _c("el-tab-pane", {
                attrs: { label: _vm.$t("To Do"), name: "ToDo" }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName == "first",
                  expression: "activeName == 'first'"
                }
              ]
            },
            [_c("productTracing", { attrs: { FlookInfo: _vm.lookInfo } })],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName == "second",
                  expression: "activeName == 'second'"
                }
              ]
            },
            [_c("OrderTree")],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName == "ToDo",
                  expression: "activeName == 'ToDo'"
                }
              ]
            },
            [_c("ToDo", { attrs: { todoID: _vm.todoID } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }