<template>
  <div class="toDo">
    <div>
      <div class="tip">
        <p>{{ $t('Menu') }}</p>
      </div>
      <div class="text-list" v-for="(v, index) in GetTraingAllPOPendingInfosData.Summarys" :key="index">
          <el-row style="width:700px;">
              <el-col :xs="24" :sm="12" :md="3" :lg="3" :xl="3">
                  <span class="lable">{{ v.Title }} </span>
              </el-col>
              <el-col :xs="24" :sm="12" :md="3" :lg="3" :xl="3">
                  <span class=" font-size: 14px; font-weight: 500; color: #454545; margin-right: 10px;">{{ v.IsPO ? $t('BUYER') : $t('SUPPLIER') }}：</span>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <span class="value">{{ v.NameCompany }}</span>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <span class="value-href" @click="clickanchor(v.RelatedId)">{{ $t('to do a total') }} : {{ v.Count ? v.Count : 0 }}</span>
              </el-col>
          </el-row>
      </div>
    </div>
    <div v-for="(v, index) in GetTraingAllPOPendingInfosData.Pendings" :key="index">
      <div class="tip">
        <p>{{ v.Title }}</p>
      </div>
      <div class="text-list" v-for="(v1, index1) in v.PendingInfos" style="" :key="index1">
        <div :id="v1.RelatedId"></div>
        <p style="margin-bottom: 10px;">
            <span style=" font-size: 16px; font-weight: 600; color: #454545;">{{ v1.IsPO ? $t('BUYER') : $t('SUPPLIER') }}：{{ v1.NameCompany }}</span>
        </p>
        <div class="text-list" v-for="(v2, index2) in v1.PendingDetails" style="margin-left: 0;padding-left: 0;font-weight: 400;margin-bottom: 17px;" :key="index2">
          <p style="margin-bottom: 10px;">
            <span style=" font-size: 12px; font-weight: 500; color: #454545;" >{{ v2.IsPO ? $t('PO') : $t('SO') }}：{{ v2.Code }}</span> <span style=" font-size: 12px; font-weight: 500; color: #454545;" >{{ $t('BUSINESS SERIAL NUMBER INTERNAL') }}：{{ v2.BusinessNoIn }}</span>
          </p>
          <div v-for="(v3, index3) in v2.PendingDetails" style="margin-bottom: 17px;" :key="index3">
              <p v-if="v3.Type=='NoRelation'">
                  <span style="font-size: 12px; font-weight: 500; margin-left: 20px;">{{ $t('NoRelation') }}</span>
              </p>
              <p v-if="v3.Type=='NoWriteComponent'">
                  <span  style="font-size: 12px; font-weight: 500; margin-left: 20px;" >{{$t('NoWriteComponent')}}</span>
              </p>
              <p v-if="v3.Type=='NoUpinfoTop'">
                  <span style="font-size: 12px; font-weight: 500; margin-left: 20px; color: Highlight">{{$t('Unrecorded phase')}}：{{v3.ProcessName}}</span>
              </p>
              <p v-if="v3.Type=='NoUpinfo'">
                  <span style="font-size: 8px; font-weight: 500; margin-left: 40px;">{{$t('No file is uploaded in phase')}}：</span>  <span >{{v3.FileName}}</span>
              </p>
              <p v-if="v3.Type=='isFinish'">
                  <span  style="font-size: 12px; font-weight: 500; margin-left: 20px; color: limegreen">{{$t('Completed')}}</span>
              </p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {GetTraingAllPOPendingInfos} from '@/api/purchaseOrd'

export default {
  props:{
    todoID:String
  },
  name: "toDo",
  data() {
    return {
      GetTraingAllPOPendingInfosData: []
    }
  },
  created() {
    var data = [];
    var newData = [];
    GetTraingAllPOPendingInfos(this.$route.query.ID).then((response) => {
      data = response.data;
      this.GetTraingAllPOPendingInfosData = data;
    });
  },
  methods:{
    clickanchor(idName) {
      document.getElementById(idName).scrollIntoView();

    },
    formatterColumn(e) {
      var helpReturn = e;
      if (helpReturn == '1NoRelation') {
        return this.$t('NoRelation');
      }

      if (helpReturn == '2NoWriteComponent') {
        return this.$t('NoWriteComponent');
      }

      if (helpReturn == '3NoUpinfo') {
        return this.$t('NoUpinfo')
      }

      if (helpReturn == 'isFinish') {
        return this.$t('Completed')
      }
    },
  },
  beforeRouteUpdate(to,from,next){
    console.log(to,from,next)
  },
  watch: {
    todoID(New) {
      this.clickanchor(New);
    }
  }
}
</script>

<style scoped lang="scss">
.toDo {
  background: #FFFFFF;
  box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 11px 21px 11px 21px;

  .title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 14px;
  }

  .tip {
    background: #FAFAFA;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #EEEEEE;
    padding: 9px 20px;
    margin-bottom: 18px;
    margin-top: 40px;
  }

  .text-list {
    //padding-bottom: 15px;
    margin-bottom: 15px;
    padding-left: 20px;
    span {
      margin-right: 10px;
    }

    .lable {
      font-size: 16px;
      font-weight: 500;
      color: #454545;
      margin-right: 10px;
    }

    .value {
      font-size: 16px;
      color: #959595;
    }

    .value-href {
      margin-left: 31px;
      color: #589EF8;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
